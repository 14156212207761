@import '../../styles/KrTheme.scss';

.KrKyeValueWrapper {
  display: flex;

  & div {
    &:first-child {
      width: 150px;
    }
  }
}
