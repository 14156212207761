@import "../../styles/theme";

$acceleratorHeight: 4px;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusText {
  font-family: 'FiraSans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $blue-normal;
  margin-bottom: 8px;

  &.lightModeText {
    color: $white;
  }

  &.statusTextPercent {
    margin-bottom: 16px;
  }
}

.quantity {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.secondaryText {
  color: $secondary;
}

.fontWeightBold {
  font-weight: bold;
}

.backLine {
  width: 100%;
  height: $acceleratorHeight;
  background-color: $blue-disabled;
  border-radius: $border-radius-8;
  position: relative;

  &.lightModeBackLine {
    background-color: $white;
  }
}

.frontLine {
  height: $acceleratorHeight;
  border-radius: $border-radius-8;
  position: absolute;
  top: 0;
  transition: width 0.3s ease-out 0s;

  &.normal {
    background-color: $blue-normal;
  }

  &.error {
    background-color: $error;
  }
}
