@import "../../styles/theme.scss";

@mixin boxShadow($color) {
  box-shadow: inset 0 -4px 0 $color;
}

.tabItem {
  position: relative;
  display: inline-block;
  color: $blue-normal;
  transition: 0.3s;

  &:hover:not(.tabItemError):not(.tabItemDisabled):not(.tabItemActive) {
    @include boxShadow($blue_03);

    color: $blue-normal;
  }

  &Active {
    @include boxShadow($blue-normal);

    color: $black;
  }

  &Error {
    color: $error;
    background: $danger;
  }
}

.tabButton {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  outline: 0;
  padding: 24px 12px;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;

  &[disabled] {
    color: $dark-grey;
    cursor: not-allowed;
  }
}

.tabTooltip {
  margin-left: 8px;
}
