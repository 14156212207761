@import '../../styles/KrTheme.scss';

.LeftMenu {
  width: 224px;
  height: auto;
  background-color: $blue-0;
  padding-top: 100px;
  margin-bottom: 0;

  .pages,
  .projects {
    .item {
      .pageLink,
      .projectLink {
        position: relative;
        display: block;
        padding: 12px 12px 12px 52px;
        font-size: 16px;
        line-height: 19px;
        color: $white;

        &:hover {
          background-color: $blue_0_hover;
        }

        &::before {
          content: '';
          position: absolute;
          left: 12px;
          display: block;
        }
      }

      .pageLink::before {
        width: 19px;
        height: 21px;
        background-image: url('../../images/icons/document.svg');
      }

      .projectLink::before {
        width: 21px;
        height: 21px;
        background-image: url('../../images/icons/briefcase.svg');
      }
    }
  }

  .divider {
    display: block;
    width: 184px;
    height: 3px;
    background-color: $blue_0_hover;
    border: none;
    margin: 12px;
  }
}
