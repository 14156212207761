@import '../../../../styles/KrTheme.scss';

.tooltip {
  display: flex;
  justify-content: flex-end;
  color: $dark-grey;

  svg {
    margin-bottom: 2px;
    fill: $blue-normal;
  }
}

.tooltipLabel {
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
}
