@import '../../styles/KrTheme.scss';

.KrEventQuickView {
  position: relative;
  max-width: 480px;
  font-size: 14px;
  line-height: 20px;
  background-color: $white;
  box-shadow: 0 8px 24px #a9a9a9;
  border-radius: 2px;

  & .picture {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-image: url('../../images/pictures/kolhoz.jpg');
  }

  & .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../images/icons/close.svg');
    border-radius: 50%;
    outline: none;
  }

  & .info {
    padding: 24px;

    & .eventName {
      padding-right: 24px;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 4px;
    }

    & .eventDateLocation {
      margin-bottom: 8px;
    }

    & .applicationDeadline {
      position: relative;
      padding-left: 32px;
      color: $green_01;
      margin-bottom: 8px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 4px;
        width: 16px;
        height: 16px;
        background-image: url('../../images/icons/clock--green.svg');
      }
    }

    & .eventDescription {
      margin-bottom: 8px;
    }

    & .industryLabel {
      color: $dark-grey;
      margin-bottom: 4px;
    }

    & .industry {
      margin-bottom: 12px;
    }

    & .moreDetailsWrapper {
      display: flex;
      justify-content: flex-end;

      & .moreDetails {
        position: relative;
        padding-right: 32px;
        color: $blue-normal;
        outline: none;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 4px;
          right: 4px;
          width: 6px;
          height: 12px;
          background-image: url('../../images/icons/shevron-right.svg');
        }
      }
    }
  }
}
