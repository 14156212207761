@import "../../styles/theme";

.h1Styles,
.h2Styles,
.h3Styles,
.h4Styles,
.h5Styles,
.caption,
.bodyLight,
.bodyMedium,
.bodyBold,
.banner,
.button,
.body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-style: inherit;
  font-family: inherit;
  text-align: left;
  word-break: break-word;
  background-color: transparent;
}

.h1Styles {
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.4px;
}

.h2Styles {
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
}

.bannerStyles {
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: 0.4px;
}

.lightWeight {
  font-weight: 300;
}

.regular {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.button {
  letter-spacing: 0.01em;
}

.xl {
  font-size: 30px;
  line-height: 44px;
}

.large {
  font-size: 24px;
  line-height: 32px;
}

.medium {
  font-size: 16px;
  line-height: 24px;
}

.frequent {
  font-size: 20px;
  line-height: 28px;
}

.small {
  font-size: 12px;
  line-height: 16px;
}

.black {
  color: $black;
}

.darkGrey {
  color: $dark-grey;
}

.lightGrey {
  color: $light-grey;
}

.light {
  color: $light;
}

.white {
  color: $white;
}

.error {
  color: $error;
}

.blueActive {
  color: $blue-active;
}

.blueNormal {
  color: $blue-normal;
}

.blueHover {
  color: $blue-hover;
}

.blueDisabled {
  color: $blue-disabled;
}

.blueLight {
  color: $blue-light;
}

.orange {
  color: $orange;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.spacing04 {
  letter-spacing: 0.4px;
}

.spacing001 {
  letter-spacing: 0.01px;
}

.normalSpacing {
  letter-spacing: normal;
}

// old typography

.h3Styles {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.h4Styles {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.h5Styles {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.caption {
  font-weight: 200;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
}

.captionText {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 0;
  color: $black-text;
}

.primary {
  color: $main;
}

.secondary {
  color: $secondary;
}

.accent {
  color: $blue;
}

.gray {
  color: $primary-text;
}

.muted {
  color: $muted-text-color;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
