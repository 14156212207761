@import "../../../../styles/theme";

%resetDefaultButtonStyles {
  display: flex;
  width: 100%;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.listBlock {
  min-height: 20px;
  width: auto;
}

.listContainer {
  z-index: 999;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  width: 100%;
  height: auto;
  max-height: 520px;
  list-style: none;
  padding: 27px 16px;
  margin: 0;
  box-shadow: 0 0 24px rgba(213, 220, 235, 0.3);
  background-color: $white;

  & .listItemText {
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.listItem {
  display: flex;
  box-sizing: border-box;
  list-style-type: none;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  max-height: 24px;

  button {
    @extend %resetDefaultButtonStyles;
  }
}

.badgesWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding-bottom: 4px;

  & > div {
    margin-right: 21px;
    margin-bottom: 24px;
  }
}

.badge {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}

.title {
  color: $light-grey;
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

ul {
  padding-inline-start: 0;
}

.link {
  color: $blue-normal;
  text-decoration: underline;
}

.nomenclatureList {
  margin-bottom: 35px;
}

.productItem {
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.showAllButton {
  @extend %resetDefaultButtonStyles;

  padding-top: 27px;
  font-size: 16px;
  line-height: 24px;
  color: $blue-normal;
  text-decoration: underline;
  cursor: pointer;
}
