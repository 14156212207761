@import "../../styles/theme";

$circleSize: 57px;

.circle {
  width: $circleSize;
  height: $circleSize;
  border-radius: calc($circleSize / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $muted-text-color;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    opacity: 0.7;
  }
}
