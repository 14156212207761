@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.title {
  line-height: 20px;
  margin-bottom: 12px;
}

.radioWrapper {
  margin: 12px 8px;
  line-height: 24px;
}
