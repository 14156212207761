@import '../../styles/theme.scss';

.contentWrapper {
  padding: 48px 24px 56px 24px;

  .prompt,
  .gratitude {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
  }

  .gratitude {
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .clarification {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .ratingBlock {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    margin-bottom: 24px;
  }
}

.postponeButton {
  margin-right: 16px;
}
