@import '../../styles/KrTheme.scss';

.dropListLoadingWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 112px;
}

.default {
  background-color: rgba($white, 0.6);
}

.withText {
  background-color: $white;
}

.text {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
}

.blueLoader {
  & div::after {
    background: $blue-normal;
  }
}
