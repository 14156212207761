@import "../../styles/theme";

.openPanelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $box-shadow;
  max-width: 905px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #eee;
  margin-bottom: 29px;
}

.panelHeader {
  padding: 0 26px 0 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 114px;
  align-items: flex-start;
  background-color: #f2f5fe;
  box-sizing: border-box;
}

.panelTitle {
  display: flex;
  flex-direction: column;

  & span {
    font-family: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000;

    &:first-of-type {
      font-weight: normal;
      color: #7a7a7c;
      margin: 25px 0 8px 0;
    }
  }
}

.panelBody {
  width: 100%;
  background-color: $white;
  min-height: 135px;
}

.roleWrapper {
  color: $white;
  background-color: $light-grey;
}

.blueLight {
  background-color: $blue-light;
}

.lightGray {
  background-color: $light-grey;
}

.darkGray {
  background-color: $dark-grey;
}

.white {
  background-color: $white;
}

.disabled {
  background-color: $disabled;
}

.darkBlue {
  background-color: $dark-blue;
}
