@import '../../styles/KrTheme.scss';

.container {
  position: relative;
}

.sameStyles {
  [class*=KrTooltip_container] {
    margin-left: 12px;
  }

  [class*=KrTooltip_container] svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: $blue-normal;
    }
  }

  [class*=grid_col] {
    flex: auto;
    max-width: 100%;
    padding: 0;
  }

  [class*=wrap] {
    display: flex;

    & span[class*=KrFieldWrapper_label] {
      font-size: 16px;
    }

    & span[class*=KrFieldWrapper_optionalText] {
      font-size: 16px;
    }
  }

  [class*=searchInput] {
    box-sizing: border-box;
  }

  [class*=MultiSelectedItems_container] {
    margin-top: 0;
  }

  [class*=errorMessage] {
    position: absolute;
    margin: 0;
  }

  [class*=dropItemsWrap] {
    max-height: 283px;
    overflow: auto;
  }
}

.sameStyles.withItems {
  div[class*=MultiSelectedItems_container] {
    margin-top: 16px;
  }
}

.sameStyles.disabled {
  [class*=KrAutocomplete_container] {
    pointer-events: none;
  }

  input {
    pointer-events: none;
    padding: 8px 24px;
    color: $dark-grey;
    background-color: $disabled;
  }

  [class*=description] > span {
    color: $light-grey;
  }

  [class*=rightGroup] > span {
    color: $light-grey;
  }
}
