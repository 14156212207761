@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  align-items: center;
  color: $errorActive;

  svg path {
    fill: $errorActive;
  }
}
