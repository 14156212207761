@import '../../../styles/KrTheme.scss';

.wrap {
  width: fit-content;
  border-radius: 24px;
  background: $blue-normal;
}

.value {
  color: $white;
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;
}

.selectedItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 22px 6px 16px;
}

.buttonClose {
  &:active {
    outline: none;
  }
}

.icon {
  color: $white;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
  color: $light-grey;
  background-color: $light-grey;
}
