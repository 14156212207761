$brandColor: #326FB9;
$hoverColor: #1890FF;
$badgeColor: #E13C39;
$textWhite: #ffffff;
$muted-text-color: #a2a1bb;
$white: #fff;
$menuHeight: 80px;
$paddingHorizontal: 20px;
$dodgerBlue: #1890ff;

//adaptive mixins
$break-1024: 1024px;
$break-1200: 1200px;
$break-1400: 1400px;
$break-900: 900px;
$break-768: 768px;
$break-600: 600px;

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content;
  }
}

// text colors
$muted-text-color: #a2a1bb;
$dark-text: #15122f;
$primary-text: #656289;
$error-text: #db4444;
$dark-error-text: #da453f;
$black-text: #000;
$link: #3969e6;
$darkGray: #7a7a7c;
$lightGray: #9c9d9e;
$skyBlue: #f2f5fe;
$blueNormal: #6177ff;
$blueDark: #2c386b;
$blueDisabled: #d5dceb;
$blueHover: #788bfb;
$blueLight: #dce8ff;
$orange: #ff8515;

// main theme
$main: #0a2750;
$secondary: #3579b8;
$third: #a9c9e7;
$fourth: #dff0ff;
$background: #f7f7fb;
$error: #eb5757;
$accent: #3f75ff;

$grey: #828282;
$whiteGrey: #eee;
$secondaryAccent: #3579b8;
$greyLight: #f9f9f9;

// fonts
%font {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
}

%fontRoboto {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
}

%fontRobotoBold {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
