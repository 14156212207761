//*************** Figma palette ***************
// Primary Color
$palette-primary: #4d66ff;
$palette-primary-dark: #293bad;
$palette-primary-light: #f1f3ff; // rgba(77, 102, 255, 0.08)
$palette-primary-border: #d9d9d9;

// Secondary Color
$palette-secondary: #c6c6c6;
$palette-secondary-dark: #717171;
$palette-secondary-light: #f6f6f6;
$palette-black: #121212;
$palette-white: #fff;

// Status Color
$palette-info: #9149ff;
$palette-bg-info: #f7f1ff;
$palette-warning: #d24600;
$palette-bg-warning: #fcf1eb;
$palette-success: #1b8900;
$palette-bg-success: #edf6eb;
$palette-error: #d24600;
$palette-error-hover: #be0114;
$palette-error-active: #86010e;
$palette-bg-error: #feebed;

// Typography Color
$palette-text: $palette-black;
$palette-text-important: $palette-primary;
$palette-text-additional: $palette-secondary-dark;

// Buttons Color
$palette-btn: $palette-primary; // default
$palette-btn-hover: #3c52d9;
$palette-btn-focus: $palette-primary-dark; // active && focus
$palette-btn-disabled: #e0e3f9;

// Shadows
$palette-shadow-box: rgba(18, 18, 18, 0.18);
//*********************************************
