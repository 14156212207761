@import '../../../styles/KrTheme.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-bottom: 16px;
  color: $blue-normal;
}

.text {
  font-size: 14px;
  color: $blue_03;
}
