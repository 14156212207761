@import '../../styles/KrTheme.scss';

.Loader {
  &.wrappedLoaded {
    display: block;
  }

  &.overlayContainer {
    position: relative;
  }

  & .withCaption {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  & .asOverlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
  }

  & .asOverlaySafe {
    z-index: 100;
  }

  & .safeOverlay + div {
    opacity: $transparentOpacity;
  }

  & .overlayContent {
    visibility: hidden;
  }

  & .overlayBlock {
    position: absolute;
    z-index: 5000;
  }

  & .loadingOverlayTransparent {
    visibility: visible;
    opacity: $transparentOpacity;
  }

  & .container {
    position: relative;

    & .item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      & .point {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: $blue-light;
      }
    }
  }

  &.centeredNoCaption {
    display: inline-block;
    text-align: center;

    & .container {
      display: inline-block;
    }
  }

  & .loaderCaption {
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
  }
}
