@import '../../styles/KrTheme.scss';

$sizeRadioCircle: 16px;

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 6px;
  cursor: pointer;
  user-select: none;

  @extend %font;

  &.checked {
    background-color: $blue-light;
    border-radius: 2px;
  }
}

.containerColumn {
  flex-direction: column;
  align-items: center;
}

.containerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkMark {
  display: flex;
  order: 1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: $sizeRadioCircle;
  min-height: $sizeRadioCircle;
  width: $sizeRadioCircle;
  min-width: $sizeRadioCircle;
  border: 1px solid $blue-active;
  border-radius: 50%;
  background-color: $white;

  .checked {
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    border-radius: 14px;
    background-color: $blue-active;
  }

  &:hover {
    border-color: $blue-hover;

    & > .checked {
      background-color: $blue-hover;
    }
  }

  &.error {
    border-color: $error !important;

    & > .checked {
      background-color: $error !important;
    }
  }
}

.checkedWrapper {
  border-color: $blue-normal;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label {
  display: flex;
  font-size: 16px;
}

.description {
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  margin-top: 8px;
  max-width: 190px;
  width: 100%;
}

.descriptionItem {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: $primary-text;
  margin-bottom: 8px;
  margin-top: 0;
}

.disabledBackground {
  background-color: $disabled;
}

.disabled {
  border-color: $disabled;
  color: $disabled;
}
