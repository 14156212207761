@import "../../styles/theme";

.header {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  height: 24px;
}

.description {
  @include setFont(16px, 24px, normal, $dark-grey);

  margin-bottom: 20px;
  display: inline-block;

  b,
  strong {
    font-weight: 900;
    color: $black-text;
  }
}

.description_ {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  height: 81px;
  margin-bottom: 20px;
  color: #7a7a7c;
}

.calcResults {
  & > span:first-of-type {
    @include setFont(16px, 24px, normal, $dark-grey);

    margin-bottom: 20px;
    display: inline-block;
  }

  & > span:last-of-type {
    @include setFont(16px, 24px, normal, #000);

    margin-bottom: 20px;
    display: inline-block;
  }
}

.plannedAmount {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;

  & > span:first-of-type {
    color: #7a7a7c;
  }

  & > span:last-of-type {
    color: #000;
  }

  &.resultAmount {
    & > span:first-of-type {
      @include setFont(16px, 24px, normal, $dark-grey);

      margin-bottom: 20px;
      display: inline-block;
    }

    & > span:last-of-type {
      @include setFont(16px, 24px, normal, #000);

      margin-bottom: 20px;
      display: inline-block;
    }
  }
}
