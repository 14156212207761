@import '../../styles/KrTheme.scss';

.container {
  @extend %font;
}

.errorMessage {
  font-size: 14px;
  line-height: 20px;
  color: $error-text;
  margin-top: 4px;
  padding-left: 8px;
}
