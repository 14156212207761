@import "../../styles/theme";

$paddingLeft: 24px;
$marginCaption: 7px;

.dropdownWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif; // inherit !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  height: 24px;
  flex-grow: 1;
  padding: 0 23px;
  background-color: $blue-light;
  border-radius: 28px;

  /* remove clear button for IE */
  &::-ms-clear {
    display: none;
  }

  &::-ms-reveal {
    display: none;
  }
}

.inputPointer {
  cursor: pointer;
}

.input:disabled {
  background-color: $disabled;
}

.label {
  position: absolute;
  left: 24px;
  top: 11px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: $dark-grey;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;

  &.focused {
    top: -33px;
    left: 24px;
  }

  &.hideLabel {
    display: none;
  }
}

.required {
  color: $error;
  padding-left: 3px;
}

.icon {
  width: fit-content;
  height: fit-content;
  padding: 0 0 0 13px;
  border: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;
  margin-right: 21px;
  background-color: transparent;
  pointer-events: all;
}

.input:disabled ~ .label {
  color: $light-grey;
}

input.input:disabled ~ button.icon {
  pointer-events: none;
  cursor: default;
}

.indicator {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 1px;
  width: 100%;

  &.active {
    bottom: -1px;
    height: 2px;
    background-color: $blue-light;

    &.error {
      background-color: $error;
      bottom: 20px;
      left: 0;
    }
  }
}

.message {
  display: block;
  width: 100%;
  line-height: 12px;
  font-size: 16px;
  height: 16px;
  margin-top: $marginCaption;
  margin-left: $paddingLeft;

  &.error {
    color: $error;
    font-weight: normal;
  }
}

.wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: $blue-light;
  border: 1px solid $blue-light;
  border-radius: 28px;
  height: $input-height;

  &.fitContent {
    width: fit-content;
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabledWrapper {
    background-color: $disabled;
    cursor: no-drop;

    .inputPointer {
      cursor: no-drop;
    }
  }

  &.active {
    z-index: 6;
    border: 1px solid $blue-hover;
  }
}

.selects {
  list-style-type: none;
  box-sizing: border-box;
  padding: (calc($input-height / 2) + 24) 0 7px 0;
  box-shadow: $box-shadow;
  margin: 0;
  width: 100%;
  height: auto;
  background-color: $white;
  opacity: 1;
  outline: none;
  position: absolute;
  left: 0;
  top: calc($input-height / 2);
  max-height: 250px;
  overflow-y: auto;
  z-index: 5;
  border: 1px solid $blue-normal;

  &.inactive {
    display: none;
  }

  &.active {
    display: block;
  }
}

.items {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  padding: 7px 16px 9px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  cursor: pointer;

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  &.active {
    background-color: $light-grey;
  }

  &.inactive {
    background-color: transparent;
  }

  &:hover {
    background-color: $light;
  }

  a {
    color: #6177ff;
    text-decoration: underline;
  }
}

.itemsAdditional {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

button.icon > svg {
  pointer-events: none;
}

.blueLoader {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.blueLoader div::after {
  background: $blue-normal;
}
