/* eslint-disable font-family-no-missing-generic-family-keyword */
@import "../../../../styles/theme.scss";

.notificationListHeader {
  width: 437px;
  height: 48px;
  background-color: $dodgerBlue;
  display: flex;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;

  & > .notificationsNumber {
    @extend %fontRoboto;

    font-weight: 400;
    font-size: 14px;
    color: $white;
  }

  & > .showMore {
    @extend %fontRoboto;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $white;
    background: url(../../../../assets/icons/arrow-right.svg) no-repeat right;
    padding-right: 12px;

    & > .moreButton:active {
      border: 0;
      background: none;
      text-decoration: none;
      outline: none;
    }

    & > .moreButton {
      border: none;
      background: none;
      color: $white;
      cursor: pointer;
    }
  }
}

.modalWrapperNotification {
  position: fixed;
  width: 437px;
  top: 75px;
  left: calc(100% - 440px);
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1200;
  opacity: 1;
  overflow: hidden;

  &.modalWindowMobile {
    top: 130px !important;
  }

  & > [class*=rcs-custom-scroll] > [class*=rcs-outer-container] {
    & > div:nth-child(1) {
      & > [class*=rcs-custom-scrollbar] {
        position: absolute;
        height: 100%;
        width: 5px;
        right: 3px;
        opacity: 1;
        z-index: 1;
        transition: opacity 0.4s ease-out;
        padding: 6px 0;
        box-sizing: border-box;
        will-change: opacity;
        pointer-events: none;

        & > [class*=rcs-custom-scroll-handle] {
          height: 65px;
          position: absolute;
          width: 100%;

          & > [class*=rcs-inner-handle] {
            height: calc(100% - 12px);
            margin-top: 6px;
            background-color: #d4d4d8;
            border-radius: 3px;
          }
        }
      }
    }

    & > div:nth-child(2) {
      transition: height 0.1s ease-out;
      overflow-y: scroll;
    }
  }
}

.contentBlock {
  border: 0.5px solid transparent;
}

.listWrapper {
  max-height: 381px;

  &.oneNotification {
    max-height: 95px;
  }

  &.twoNotifications {
    max-height: 190px;
  }

  &.threeNotifications {
    max-height: 285px;
  }

  & > div {
    margin-top: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #dadada;
    }
  }
}

.cleanNotifications {
  width: 437px;
  height: 40px;
  background-color: #f7f7f7;
  padding: 12px 0 0 40px;
}

.cleanButton {
  @extend %fontRoboto;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 16px;
  color: #8f8f8f;
  cursor: pointer;
}

.recomendationsBlock {
  background-color: #f2f5fe;
  width: auto;
  height: auto;
  padding: 0 0 0 40px;
}

.recomendationsHeader {
  @extend %fontRoboto;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333;
}

.modalWrapper_ {
  position: fixed;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  top: 381px;
  left: calc(50% + 15px);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-width: 800px;
  min-width: 312px;
  max-height: 500px;
  width: 100%;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1200;
  overflow: scroll;

  .leftMenu {
    transform: translate(-35%, -50%);
  }

  @include to($break-1200) {
    width: 50%;
  }

  @include to($break-768) {
    transform: translate(-50%, -50%);
  }
}

.modalContent {
  width: 100%;
}

.RecomendationsList {
  & > div:not(:last-child) {
    border-bottom: 1px solid #dddfe7;
  }
}

.Recomendation {
  min-height: 80px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  margin-right: 10px;

  .caption {
    & > div:nth-child(1) {
      @extend %fontRoboto;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #6177ff;
    }

    & > div:nth-child(2) {
      @extend %fontRoboto;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a7a7c;
    }
  }

  .RecomendationPicture {
    background-image: url("../../../../assets/images/recomendation.png");
    border-radius: 50%;
    width: 44px;
    height: 44px;
  }

  .RecomendationPictureBlock {
    margin-right: 34px;
  }
}
