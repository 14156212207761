@import "../../styles/theme";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 76px;
  padding: 8px 16px;
  border: 1px dashed $palette-secondary;
  transition: background-color 0.2s ease;

  &.target,
  &:hover {
    background-color: $palette-primary-light;
  }
}

.dropArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;

  &.target {
    z-index: 10;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  cursor: pointer;
}

.errorMessage {
  margin-top: 4px;
}

.hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: circle(0 at 50% 50%);
}
