@import '../../styles/KrTheme.scss';

.KrOperatorCardQuickView {
  position: relative;
  max-width: 376px;
  font-size: 14px;
  line-height: 20px;
  background: $white;
  box-shadow: 0 8px 24px #a9a9a9;
  border-radius: 2px;

  & .closeIcon {
    position: absolute;
    top: 20px;
    right: 28px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../images/icons/close.svg');
  }

  & .top {
    padding: 24px 24px 12px 24px;

    & .operatorName {
      padding-right: 32px;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 4px;
    }

    & .operatorDescription {
      color: $dark-grey;
    }
  }

  & .bottom {
    display: flex;
    padding: 16px 24px 24px 24px;
    border-top: 1px solid $blue-disabled;

    & .operatorPhoneWrapper {
      margin-right: 24px;
    }

    & .label {
      color: $dark-grey;
      margin-bottom: 4px;
    }

    & .link {
      color: $blue-normal;
    }
  }
}
