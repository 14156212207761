@import '../../styles/KrTheme.scss';

.KrNotification {
  position: relative;
  width: 100%;
  padding: 18px 16px 18px 44px;
  font-size: 14px;
  line-height: 20px;
  background-color: $white;
  border: 1px solid $blue-light;
  border-radius: 4px;

  & svg {
    position: absolute;
    top: 20px;
    left: 16px;
  }

  &.info {
    background-color: $blue-light;
    border-left: 4px solid $blue-normal;

    & svg {
      transform: rotate(180deg);
      fill: $blue-normal;
    }
  }

  &.success {
    background-color: $lightgreen-background;
    border-left: 4px solid $green_01;
  }

  &.error {
    background-color: $red_02;
    border-left: 4px solid $error;

    & svg {
      & path {
        fill: $error;
      }
    }
  }

  &.warning {
    background-color: $orange-background;
    border-left: 4px solid $warning;

    & svg {
      & path {
        fill: $warning;
      }
    }
  }

  & .bold {
    font-weight: bold;
  }

  & a {
    color: $blue-normal;
  }

  & .document {
    margin: 12px 0 0 -10px;
  }

  & ul {
    margin-top: 12px;
    margin-left: -20px;
  }

  &.details > div:last-of-type {
    position: relative;
    display: inline-block;
    color: $blue-normal;
    margin-top: 8px;
    cursor: pointer;
  }

  &.details > div:last-of-type svg {
    position: relative;
    left: 6px;
    top: 0;
  }
}
