@import "../../styles/theme";

.wrapper {
  width: 100%;
  display: flex;
}

.root {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  padding: 0;
  border: 1px solid $blue-normal;
  outline: unset;
  background: $white;

  &:hover {
    border-color: $blue-hover;

    & > div.flag {
      background-color: $blue-hover;
    }
  }
}

.passed {
  background-color: $white;
}

.backgroundColorWhite {
  background-color: $white;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: $blue-normal;
}

.next {
  background-color: $white;
}

.line {
  position: absolute;
  // bottom: 18px;
  bottom: calc(50% - 0.5px);
  width: 100%;
  border-top: 1px solid $blue-normal;
}

.stepWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.last {
  width: 48px;
}
