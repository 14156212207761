@import "../../styles/theme";

.tableContainer {
  width: 100%;
}

.tableColumns {
  display: flex;
  flex-direction: row;
  width: inherit;
  height: auto;
}

.tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $blue;
  border-left: none;
  width: 100%;
  min-width: 90px;
  word-wrap: break-word;

  &:first-of-type {
    border: 1px solid $blue;
  }

  & > input {
    @include setFont(14px, 20px, normal, $dark-text);

    outline: none;
    border: none;
    text-align: center;
    width: 100%;
    background-color: transparent;
  }

  & > button {
    @include setFont(14px, 20px, normal, $dark-text);

    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 10px;

    &:hover {
      background-color: #d7d7d7;
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  width: inherit;

  & > .tableColumns > .tableCell {
    &:first-of-type {
      border-radius: 20px 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 20px 0 0;
    }
  }
}

.tableRows {
  display: flex;
  flex-direction: column;

  & .tableColumns:hover {
    background-color: #ebebeb;
  }

  & > .tableColumns:last-of-type {
    border-radius: 0 0 20px 20px;
    overflow: hidden;

    & > .tableCell {
      &:first-of-type {
        border-radius: 0 0 0 20px;
      }

      &:last-of-type {
        border-radius: 0 0 20px 0;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.selectedRow {
  background-color: $light;
}
