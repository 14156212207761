@import '../../styles/KrTheme.scss';

$fontSize: 16px;
$disabled: #eee;

input::-ms-clear {
  display: none;
}

.container {
  position: relative;
}

.searchInput {
  width: 100%;
  padding: 0 54px 0 24px;
  min-height: 40px;
  background-color: $blue-light;
  border-radius: $border-radius-100;
  outline: none;
  border: 1px solid transparent;
  font-size: $fontSize;

  &:focus {
    outline: 0;
    background-color: $white;
    border: 1px solid $blue-normal;
  }

  &.errorInput {
    border-color: $error;
  }

  ::placeholder {
    color: $dark-grey;
  }

  &.disabled {
    color: $dark-grey;
    background-color: $disabled;
    cursor: no-drop;
    border: 1px solid $disabled;
  }

  &.activeBorder {
    outline: 0;
    background-color: $white;
    border: 1px solid $blue-normal;
  }
}

.dropItemsWrap {
  position: absolute;
  width: 100%;
  z-index: $dropdown-z-index;
}

.dropItems {
  position: relative;
  width: 100%;
}

.list {
  box-shadow: $drop-shadow;
  padding: 0;
  margin: 3px 0 0;
  width: 100%;
  border-radius: 2px;

  li {
    list-style-type: none;

    button span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.errorMessage {
  font-size: 14px;
  line-height: 20px;
  color: $error;
}

.header {
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.inputWrap {
  position: relative;
}

.chevronButtonWrapper {
  position: absolute;
  top: 50%;
  right: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  transform: translateY(-50%);
  transition: 0.3s;
  cursor: pointer;

  &Open {
    transform: translateY(-50%) rotate(180deg);
  }
}

.inputWrapIcon {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 28px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  background-repeat: no-repeat;

  & + .searchInput {
    padding-left: 56px;
  }

  &.search {
    background-image: url('../../images/icons/search.svg');
  }
}

.closeButtonWrap {
  position: absolute;
  color: $blue-normal;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.readOnly {
  padding-top: 10px;
  color: $black-text;
  font-size: $fontSize;
}

.sameStyles {
  [class*=wrap] {
    display: flex;
  }

  [class*=dropItemsWrap] {
    max-height: 283px;
    overflow: auto;
  }

  span[class*=label] {
    font-size: 16px;
  }

  [class*=optionalText] {
    font-size: 16px;
  }

  [class*=errorMessage] {
    position: absolute;
    margin: 0;
  }

  [class*=grid_row] {
    display: flex;
    margin: 0;
  }

  [class*=searchInput] {
    box-sizing: border-box;
  }

  [class*=KrTooltip_container] svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: $blue-normal;
    }
  }
}
