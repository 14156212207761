@import "../../../../styles/theme";

.wrapper {
  display: flex;
  width: 40%;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  & > *:first-child {
    margin-bottom: 17px;
  }

  & > *:nth-child(n):not(:first-child), {
    margin-bottom: 8px;
  }
}

.signedText {
  font-weight: bold;
  color: $blue-normal;
}

.companyName {
  font-weight: bold;
  color: $black;
}

.text {
  color: $dark-grey;
}

.attachWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
}

.buttonWrapper {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  align-items: center;
}

.attach {
  width: 60%;
}

.signButton {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.errorMessage {
  display: flex;
  margin-bottom: 40px;
  color: $error-text;
}

.groupWrapper {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 8px;
  border: 1px solid $blue-hover;
  box-sizing: border-box;
  border-radius: 32px;
  margin-bottom: 40px;
}
