@import '../../styles/KrTheme.scss';

.KrNotificationPanelCard {
  width: 100%;
  padding: 16px;
  background: white;
  border: 1px solid $light;
  border-radius: 2px;
  box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04);
  margin-bottom: 16px;

  & .cardHeader {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
    margin-bottom: 8px;
  }

  & .cardText {
    font-size: 16px;
    line-height: 24px;
  }

  & .cardButton {
    height: 42px;
    padding: 10px 40px;
    font-size: 18px;
    line-height: 22px;
    color: $white;
    background-color: $blue-normal;
    border-radius: 100px;
    margin-top: 8px;
    outline: none;
  }
}
