@import '../../styles/KrTheme.scss';

.KrEventPreviewCard {
  max-width: 368px;
  min-height: 246px;
  padding: 12px;
  background-color: $blue-light;

  & .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & .headerIcon {
      width: 24px;
      height: 24px;
      background-image: url('../../images/icons/document2--green.svg');
      margin-right: 4px;
    }

    & .headerText {
      font-size: 12px;
      line-height: 16px;
      color: $green_01;
    }

    & .helperIcon {
      width: 16px;
      height: 16px;
      background-image: url('../../images/icons/question.svg');
      background-size: contain;
      border: none;
      margin-left: auto;
      margin-right: 0;
      cursor: pointer;
      outline: none;
    }
  }

  & .eventName {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
  }

  & .eventLocation {
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
    margin-bottom: 8px;
  }

  & .datesBlock {
    columns: auto 2;
    margin-bottom: 52px;

    & .datesTitle {
      font-size: 12px;
      line-height: 16px;
      color: $dark-grey;
      margin-bottom: 4px;
    }

    & .dates {
      font-size: 14px;
      line-height: 20px;
    }
  }

  & .tags {
    font-size: 14px;
    line-height: 20px;
    color: $blue-normal;
  }
}
