@import '../../styles/KrTheme.scss';

@mixin bordered() {
  border-left: 1px solid $blue-disabled;
  border-right: 1px solid $blue-disabled;
}

.container {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  &.full {
    @include bordered();

    border-bottom: 1px solid $blue-disabled;
  }

  &.side {
    @include bordered();
  }
}

.headerRow {
  position: relative;
}

.headerCell {
  padding: 24px 20px;
  vertical-align: middle;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: $blue-lighter;
  box-shadow: inset 0 -1px 0 #ccd4ec, inset 0 1px 0 #ccd4ec;

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
}

.bodyRow {
  position: relative;

  &.selectedRow {
    background: $blue-light;
  }

  &:hover {
    background: $blue-light;
  }
}

.bodyCell {
  padding: 24px 20px;
  white-space: pre-wrap;
  word-break: break-word;

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }

  &:hover {
    cursor: pointer;
  }
}

.quickView {
  position: relative;

  tr,
  td {
    padding: 0;
  }
}

.infoContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 216px;
  width: 100%;

  &.empty {
    min-height: 0;
  }
}

.stateWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
