@import '../../styles/KrTheme.scss';

$triangleWidth: 10px;
$triangleMargin: 16px;

@mixin triangleBottom($position: 'left') {
  bottom: -$triangleMargin;
  left: 50%;

  &::before {
    top: -$triangleWidth;
    border-left: $triangleWidth solid transparent;
    border-right: $triangleWidth solid transparent;
    border-bottom: $triangleWidth solid $tooltipBackground;

    @if ($position == 'left') {
      right: $triangleMargin;
    }

    @if ($position == 'right') {
      left: $triangleMargin;
    }
  }

  &::after {
    @include tooltipPosition('horizontal');

    top: calc(-#{$triangleWidth} - #{$triangleMargin});
  }
}

@mixin tooltipPosition($position: 'horizontal') {
  width: 100%;
  height: 100%;

  @if ($position == 'horizontal') {
    left: 0;
  }

  @if ($position == 'vertical') {
    top: 0;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  padding: 14px 16px;
  width: max-content;
  min-width: 136px;
  max-width: 420px;
  border-radius: 6px;
  background: $tooltipBackground;
  animation: infoTooltipFadeIn 0.1s ease forwards;
  box-shadow: $drop-shadow;
  cursor: auto;
  z-index: $tooltip-z-index;

  & a {
    color: $blue-normal;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $blue-hover;
    }
  }

  &.hidden {
    animation: infoTooltipFadeOut 0.1s ease alternate;
  }

  &::before,
  &::after {
    @include pseudo();
  }

  &::before {
    width: 0;
    height: 0;
  }

  &::after {
    z-index: -1;
  }

  &.left {
    top: 50%;
    left: -16px;
    transform: translate(-100%, -50%);

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $tooltipBackground;
    }

    &::after {
      @include tooltipPosition('vertical');

      right: -#{$triangleMargin};
    }
  }

  &.right {
    top: 50%;
    right: -16px;
    transform: translate(100%, -50%);

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $tooltipBackground;
    }

    &::after {
      @include tooltipPosition('vertical');

      left: -#{$triangleMargin};
    }
  }

  &.top {
    top: -16px;
    left: 50%;
    transform: translate(-50%, -100%);

    &::before {
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $tooltipBackground;
    }

    &::after {
      @include tooltipPosition('horizontal');

      top: $triangleMargin;
    }
  }

  &.bottom {
    bottom: -16px;
    left: 50%;
    transform: translate(-50%, 100%);

    &::before {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $tooltipBackground;
    }

    &::after {
      @include tooltipPosition('horizontal');

      top: -#{$triangleMargin};
    }
  }

  &.bottom-right {
    bottom: -16px;
    right: 0%;
    transform: translate(0%, 100%);

    &::before {
      position: absolute;
      top: -10px;
      left: 90%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 11px solid $tooltipBackground;
    }

    &::after {
      @include tooltipPosition('horizontal');

      top: calc(-#{$triangleWidth} - #{$triangleMargin});
    }
  }

  &.bottom-left {
    bottom: -16px;
    left: 0%;
    transform: translate(0%, 100%);

    &::before {
      position: absolute;
      top: -10px;
      left: 10%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 11px solid $tooltipBackground;
    }

    &::after {
      @include tooltipPosition('horizontal');

      top: calc(-#{$triangleWidth} - #{$triangleMargin});
    }
  }

  &.inputErrorLeft {
    @include triangleBottom('left');

    margin-left: 26px; // ie
    transform: translate(-100%, 100%);
  }

  &.inputErrorRight {
    @include triangleBottom('right');

    transform: translate(-26px, 100%);
  }
}

.tooltipContent {
  color: #000;
  font-size: 14px;
  line-height: 20px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.wrapper {
  width: 240px;
  height: 240px;
  background-color: #eee;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@keyframes infoTooltipFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes infoTooltipFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.sameStyles {
  color: $black;
}
