@import '../../styles/theme';

$sizeRadioCircle: 16px;

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: no-drop;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @extend %font;
}

.containerColumn {
  flex-direction: column;
  align-items: center;
}

.containerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checked {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-radius: 14px;
  background-color: $blue-disabled;
}

.checkMark {
  display: flex;
  order: 1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: $sizeRadioCircle;
  min-height: $sizeRadioCircle;
  width: $sizeRadioCircle;
  min-width: $sizeRadioCircle;
  border: 1px solid $blue-disabled;
  border-radius: 50%;
  background-color: $white;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.labelWrapper {
  position: relative;
  display: inline-flex;
  order: 1;
  align-self: flex-start;
}

.label {
  display: flex;
  color: $light-grey;
}

.descriptionWrapper {
  display: inline-flex;
  align-self: flex-start;
  order: 2;
  min-width: 14px;
  width: 14px;
  height: 24px;
  margin-left: 12px;
  color: $dark-grey;
}

.description {
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  margin-top: 8px;
  max-width: 190px;
  width: 100%;
}

.descriptionLeft {
  order: 0;
  margin-left: 0;
  margin-right: 3px;
}

.descriptionItem {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: $light-grey;
  margin-bottom: 8px;
  margin-top: 0;
}

.enabled {
  cursor: pointer;

  & .checked {
    background-color: $blue-active;
  }

  & .checkMark {
    border-color: $light-grey;

    &.error {
      border-color: $error !important;

      & > .checked {
        background-color: $error !important;
      }
    }

    &:hover {
      border-color: $blue-hover;

      & > .checked {
        background-color: $blue-hover;
      }
    }
  }

  & .checkedWrapper {
    border-color: $blue-normal;
  }

  & .label {
    color: inherit;
  }

  & .descriptionItem {
    color: $primary-text;
  }
}

.small {
  .label {
    font-size: 14px;
  }
}

.sameStyles {
  color: $blue-hover;
}
