/* stylelint-disable no-descending-specificity */
@import '../../styles/KrTheme.scss';

.wrap .selected {
  color: $blue-normal;

  .check {
    display: block;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  padding: 16px 24px;
  width: 100%;
  text-align: left;

  & .check {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $blue-light;
  }

  &:focus {
    cursor: pointer;
    outline: none;
  }
}

.disabled .item {
  &,
  &:hover,
  &:focus {
    background-color: $white;
    cursor: not-allowed;
    color: $light-grey;
  }
}
