@import '../../styles/KrTheme.scss';

$paddingLeft: 24px;
$marginCaption: 7px;

.error {
  display: block;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  height: 16px;
  margin-top: 4px;
  color: $error;

  &.error {
    color: $error;
    font-weight: normal;
  }
}

.tree-select-rc {
  width: 100%;
  opacity: 1;
  z-index: 11102;
}

.rc-tree-select-dropdown {
  min-height: 100px;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 11102;
}

.rc-tree-select-dropdown-hidden {
  display: none;
}

.rc-tree-select-empty {
  display: none;
}

.rc-tree-select-tree-list-holder {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.child-icon-wrap {
  display: flex;

  .child-icon-arrow-down {
    height: 10px;
    width: 10px;
    border: none;
  }

  .child-icon-checkbox-wrap {
    height: 10px;
    margin-top: -4px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.parent-icon-wrap {
  display: flex;

  .parent-arrow {
    height: 10px;
    width: 10px;
    margin-top: -19px;
    margin-right: 9px;
    margin-left: 4px;
    border: none;
  }

  .parent-check {
    position: relative;
    height: 10px;
    width: 10px;
    margin-top: -5px;
    margin-right: 4px;
  }
}

.parent-check-half {
  position: absolute;
  height: 8px;
  width: 8px;
  top: 8px;
  left: 4px;
  z-index: 5;
  background-color: $blue-normal;
}

.tree-select-wrap {
  position: relative;

  .dropdownWrapper {
    position: relative;
    width: 100%;
    font-family: 'Roboto', sans-serif;
  }
}

.rc-tree-select-tree-node-content-wrapper {
  position: relative;
  display: inline-block;
  margin: 0 0 0 6px;
  padding: 0;
  width: calc(100% - 48px);
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  min-height: 40px;
  overflow: hidden;
}

.expandButton {
  margin-top: 12px;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: $dark-grey;
    margin-bottom: 4px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
    display: inline-block;

    &.hideLabel {
      display: none;
    }
  }

  .tooltip {
    display: flex;
    justify-content: flex-end;
    color: $dark-grey;

    svg {
      margin-bottom: 2px;
      fill: $blue-normal;
    }
  }

  &.hide {
    display: none;
  }

  &.rightSide {
    justify-content: flex-end;
  }
}

.tooltipLabel {
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.rc-tree-select-tree-treenode-disabled > a,
.rc-tree-select-tree-treenode-disabled > a span,
.rc-tree-select-tree-treenode-disabled > span:not(.rc-tree-select-tree-switcher) {
  color: $dark-grey;
  cursor: not-allowed;
}

.sameStyles {
  [class*=wrapper]:hover {
    background-color: $blue-light;
    border-color: $blue-light;
  }

  [class*=dropdownHeader] > span {
    font-size: 16px;
  }

  [class*=KrTooltip_container] svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: $blue-normal;
    }
  }
}

.errorBorderStyles {
  border-color: $error !important;
}

.required {
  color: $error;
  padding-left: 3px;
}

.dropdown {
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;

  span.rc-tree-select-tree-checkbox {
    width: 13px;
    height: 13px;
    margin: 0 3px;
    background-position: 0 0;
    z-index: 3;
    border: 0;
    outline: none;
    cursor: pointer;
  }
}

.item-chip {
  margin: 4px;
}

.hide {
  display: none;
}

.wrap-item-chip {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 16px;
}

.rc-tree-select-focused .rc-tree-select-selector {
  border-color: $blue-hover;
}

.rc-tree-select-multiple {
  .rc-tree-select-selector {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    height: fit-content;

    .rc-tree-select-selection-item {
      display: flex;
      flex: none;
      border-radius: 24px;
      background: $blue-normal;
      color: $white;
      margin-right: 2px;
      padding: 4px 1px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      max-height: 30px;

      svg {
        fill: $white;
        color: $white;
        height: 10px;
        width: 10px;

        path {
          stroke: $white;
        }
      }
    }

    .rc-tree-select-selection-item-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .rc-tree-select-selection-overflow {
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      align-items: center;
      background-color: transparent;
      border: none;
      height: auto;
      padding: 10px 0 10px 23px;
      margin: auto 0;

      .rc-tree-select-selection-overflow-item {
        flex: none;
        width: fit-content;
        overflow: hidden;
        word-break: break-all;
        max-height: 30px;
        line-height: 30px;
        opacity: 0.1;

        &.rc-tree-select-selection-overflow-item-suffix {
          height: 40px;
          margin-left: 4px;
          padding-left: 0;
        }

        &.rc-tree-select-selection-overflow-item-rest {
          max-width: 4px;
          display: none;
        }

        .rc-tree-select-selection-search {
          position: relative;

          .rc-tree-select-selection-search-mirror {
            position: absolute;
            z-index: 999;
            white-space: pre;
            left: 0;
            top: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

.rc-tree-select-hide-input {
  display: block;
  background: $blue-light;
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  z-index: 111;
}

.open-arrow {
  position: absolute;
  background-color: $blue-light;
  height: 20px;
  width: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: top;
  z-index: 111;

  &::after {
    position: absolute;
    display: inline-block;
    border-style: solid;
    border-width: 0.12em 0.12em 0 0;
    color: rgb(97, 119, 255);
    content: '';
    height: 7px;
    width: 7px;
    right: 5px;
    top: 50%;
    transform: rotate(135deg);
    vertical-align: top;
  }
}

.hide-arrow {
  position: absolute;
  background-color: $blue-light;
  height: 20px;
  width: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: top;
  z-index: 111;

  &::after {
    position: absolute;
    display: inline-block;
    border-style: solid;
    border-width: 0.12em 0.12em 0 0;
    color: rgb(97, 119, 255);
    content: '';
    height: 7px;
    width: 7px;
    right: 5px;
    top: 50%;
    transform: rotate(315deg);
    vertical-align: top;
  }
}

.custom-input-wrap {
  position: relative;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid transparent;

  .blueLoader {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 11111111;
    width: 100%;
    height: 120px;
    background: #fff;
    top: 100%;
    left: 0;
    box-shadow: darkgrey 0 10px 30px -5px;
  }

  .blueLoader div::after {
    background: $blue-normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-input {
    position: absolute;
    background-color: $blue-light;
    width: 100%;
    cursor: pointer;
    height: 40px;
    border-radius: 20px;
    z-index: 0;
    opacity: 0.3;
    display: none;
    line-height: 40px;
    border: 1px solid transparent;
    overflow: auto;

    &.active {
      border: 1px solid $blue-normal;
    }

    .custom-input_placeholder {
      color: $dark-grey;
    }
  }

  .arrow-button {
    position: absolute;
    right: 18px;
    top: 12px;
    z-index: 3;
    border: none;
    background: none;
  }

  .rc-tree-select {
    height: fit-content;
    z-index: 22;
    background-color: $blue-light;
    border-radius: 20px;

    .rc-tree-select-open {
      position: relative;
      z-index: 11111111;
    }

    .rc-tree-select-selection-item-remove {
      padding: 0 5px;
      background: transparent;
    }

    .rc-tree-select-selection-item-content {
      padding-left: 10px;
    }

    .rc-tree-select-selector {
      &::after {
        position: absolute;
        display: inline-block;
        border-style: solid;
        border-width: 0.12em 0.12em 0 0;
        color: rgb(97, 119, 255);
        content: '';
        height: 7px;
        right: 25px;
        top: 50%;
        vertical-align: top;
        width: 7px;
        transform: rotate(-45deg);
      }
    }

    .rc-tree-select-selector.open-arrow {
      &::after {
        position: absolute;
        display: inline-block;
        border-style: solid;
        border-width: 0.12em 0.12em 0 0;
        color: rgb(97, 119, 255);
        content: '';
        height: 7px;
        right: 25px;
        top: 50%;
        vertical-align: top;
        width: 7px;
        transform: rotate(135deg);
      }
    }

    .rc-tree-select-selection-placeholder {
      opacity: 0.6;
      pointer-events: none;
      font-size: 16px;
      font-weight: normal;
      line-height: 40px;
      height: 40px;
      flex-grow: 1;
      margin: auto 0;
    }

    .rc-tree-select-selection-search-input {
      white-space: normal;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      line-height: 0;
      min-width: 30px;
    }

    .rc-tree-select-selection-search-input::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.arrow {
  position: absolute;
  right: 10px;
  background-color: $blue_05;
  border: 1px solid #0000ff1f;
  color: $dark-grey;
  width: 10px;
  cursor: pointer;
  height: 40px;
  border-radius: 20px;
  padding-left: 23px;
  z-index: 0;
  opacity: 1;
  line-height: 40px;
}

.rc-tree-select-tree {
  margin: 0;
  border: 1px solid transparent;

  .rc-tree-select-tree-treenode {
    padding: 0 0 0 10px;
    line-height: 52px;
    white-space: nowrap;
    list-style: none;
    outline: 0;
    display: flex;
    height: 40px;
    align-items: center;

    span.rc-tree-select-tree-checkbox {
      width: 13px;
      height: 13px;
      margin: 0 3px;
      background-position: 0 0;
      z-index: 3;
      border: 0;
      outline: none;
      cursor: pointer;
    }

    &.rc-tree-select-tree-treenode-switcher-open {
      display: flex;
      flex-direction: row;
    }

    &.rc-tree-select-tree-treenode-switcher-close {
      display: flex;
      flex-direction: row;
    }

    &.rc-tree-select-tree-treenode-checkbox-checked {
      color: $blue-normal;
      background-color: $blue-light;
    }

    & span.rc-tree-select-tree-iconEle {
      display: none;
    }
  }
}

.rc-tree-select-tree-list {
  box-shadow: darkgrey 0 10px 30px -5px;
}

.rc-tree-select-disabled {
  &.rc-tree-select-selector {
    opacity: 0.3;
  }
}

.not-visible {
  opacity: 0;
}

.check-mark {
  top: unset;
}

.rc-tree-select-disabled,
.rc-tree-select-disabled input {
  cursor: not-allowed;
}

.rc-tree-select-allow-clear.rc-tree-select-multiple .rc-tree-select-selector {
  padding-right: 50px;
}

.rc-tree-select-allow-clear .rc-tree-select-clear {
  background-color: $blue-light;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: rgb(97, 119, 255);
  cursor: pointer;
  z-index: 1;
  border: none;
  font-weight: 300;
  font-size: 22px;
}

.rc-tree-select-allow-clear .rc-tree-select-clear .rc-tree-select-clear-icon {
  display: flex;
  height: 100%;
  align-items: center;
}

.chips {
  margin: 20px 0;
  height: 50px;
}

.rc-tree-select-show-arrow.rc-tree-select-multiple .rc-tree-select-selector {
  padding-right: 20px;
}

.rc-tree-select-show-arrow .rc-tree-select-arrow {
  pointer-events: none;
  position: absolute;
  right: 5px;
  top: 0;
}

.rc-tree-select-show-arrow .rc-tree-select-arrow-icon::after {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  border: 5px solid transparent;
  border-top-color: $light-grey;
  transform: translateY(5px);
}

.rc-tree-select-item {
  font-size: 16px;
  line-height: 1.5;
  padding: 4px 16px;
}

.rc-tree-select-item-group {
  color: $light-grey;
  font-weight: 700;
  font-size: 80%;
}

.rc-tree-select-item-option {
  position: relative;
}

.rc-tree-select-item-option-grouped {
  padding-left: 24px;
}

.rc-tree-select-item-option .rc-tree-select-item-option-state {
  position: absolute;
  right: 0;
  top: 4px;
  pointer-events: none;
}

.rc-tree-select-item-option-active {
  background: green;
}

.rc-tree-select-item-option-disabled {
  color: $light-grey;
}

.rc-tree-select-item-empty {
  text-align: center;
  color: $light-grey;
}

.rc-tree-select-dropdown-slide-up-appear,
.rc-tree-select-dropdown-slide-up-enter {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.rc-tree-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.rc-tree-select-dropdown-slide-up-appear.rc-tree-select-dropdown-slide-up-appear-active.rc-tree-select-dropdown-placement-bottomLeft,
.rc-tree-select-dropdown-slide-up-enter.rc-tree-select-dropdown-slide-up-enter-active.rc-tree-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}

.rc-tree-select-dropdown-slide-up-leave.rc-tree-select-dropdown-slide-up-leave-active.rc-tree-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}

.rc-tree-select-dropdown-slide-up-appear.rc-tree-select-dropdown-slide-up-appear-active.rc-tree-select-dropdown-placement-topLeft,
.rc-tree-select-dropdown-slide-up-enter.rc-tree-select-dropdown-slide-up-enter-active.rc-tree-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}

.rc-tree-select-dropdown-slide-up-leave.rc-tree-select-dropdown-slide-up-leave-active.rc-tree-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}

.rc-tree-select-tree-focused:not(.rc-tree-select-tree-active-focused) {
  border-color: $blue-hover;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode:hover {
  background-color: $blue-light;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode .draggable {
  color: $dark-grey;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode.drop-container > .draggable::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 2px red;
  content: "";
}

.rc-tree-select-tree .rc-tree-select-tree-treenode.drop-container ~ .rc-tree-select-tree-treenode {
  border-left: 2px solid #d2691e;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode.drop-target {
  background-color: $blue-light;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode.drop-target ~ .rc-tree-select-tree-treenode {
  border-left: none;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode ul {
  margin: 0;
  padding: 0 0 0 18px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode .rc-tree-select-tree-node-content-wrapper {
  position: relative;
  display: inline-block;
  margin: 0 0 0 4px;
  padding: 0;
  width: calc(100% - 46px);
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  min-height: 40px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode.filter-node > .rc-tree-select-tree-node-content-wrapper {
  color: $blue-normal !important;
  font-weight: 700 !important;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox,
.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher {
  display: flex;
  flex-direction: row;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox.rc-tree-select-tree-icon__customize,
.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-iconEle.rc-tree-select-tree-icon__customize,
.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher.rc-tree-select-tree-icon__customize {
  background-image: none;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-icon_loading {
  margin-right: 2px;
  vertical-align: top;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher.rc-tree-select-tree-switcher-noop {
  cursor: auto;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher.rc-tree-select-tree-switcher_open {
  background-position: -93px -56px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher.rc-tree-select-tree-switcher_close {
  background-position: -75px -56px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox-checked {
  background-position: -14px 0;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox-indeterminate {
  background-position: -14px -28px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox-disabled {
  background-position: 0 -56px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox.rc-tree-select-tree-checkbox-checked.rc-tree-select-tree-checkbox-disabled {
  background-position: -14px -56px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox.rc-tree-select-tree-checkbox-indeterminate.rc-tree-select-tree-checkbox-disabled {
  position: relative;
  background: #ccc;
  border-radius: 3px;
}

.rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox.rc-tree-select-tree-checkbox-indeterminate.rc-tree-select-tree-checkbox-disabled::after {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 5px;
  height: 0;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: scale(1);
  content: " ";
}

.rc-tree-select-tree:not(.rc-tree-select-tree-show-line) .rc-tree-select-tree-treenode .rc-tree-select-tree-switcher-noop {
  background: none;
}

.rc-tree-select-tree.rc-tree-select-tree-show-line .rc-tree-select-tree-treenode:not(:last-child) > ul {
  background: url("data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7") 0 0 repeat-y;
}

.rc-tree-select-tree.rc-tree-select-tree-show-line .rc-tree-select-tree-treenode:not(:last-child) > .rc-tree-select-tree-switcher-noop {
  background-position: -56px -18px;
}

.rc-tree-select-tree.rc-tree-select-tree-show-line .rc-tree-select-tree-treenode:last-child > .rc-tree-select-tree-switcher-noop {
  background-position: -56px -36px;
}

.rc-tree-select-tree-child-tree {
  display: none;
}

.rc-tree-select-tree-child-tree-open {
  display: block;
}

.rc-tree-select-tree-treenode-active {
  background: rgba(0, 0, 0, 0.1);
}

.rc-tree-select-tree-node-selected {
  background-color: $blue-light;
  box-shadow: 0 0 0 1px #ffb951;
  opacity: 0.8;
}

.rc-tree-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px -16px;
}

.rc-tree-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px 0;
}

.rc-tree-select-tree-icon__docu {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px -32px;
}

.rc-tree-select-tree-icon__customize {
  margin-right: 2px;
  vertical-align: top;
}

.rc-tree-select-tree-title {
  display: inline-block;
}

.rc-tree-select-tree-indent {
  display: inline-block;
  vertical-align: bottom;
  height: 0;
}

.rc-tree-select-tree-indent-unit {
  width: 16px;
  display: inline-block;
}

.rc-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.rc-dialog-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.rc-dialog-title {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
}

.rc-dialog-content {
  position: relative;
  background-color: $white;
  border: none;
  border-radius: 6px 6px;
  background-clip: padding-box;
}

.rc-dialog-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: 21px;
  position: absolute;
  right: 20px;
  top: 12px;
  font-weight: 700;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
  text-decoration: none;
}

.rc-dialog-close-x::after {
  content: "×";
}

.rc-dialog-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  text-decoration: none;
}

.rc-dialog-header {
  padding: 13px 20px 14px;
  border-radius: 5px 5px 0 0;
  background: #fff;
  color: $dark-grey;
  border-bottom: 1px solid #e9e9e9;
}

.rc-dialog-body {
  padding: 20px;
}

.rc-dialog-footer {
  border-top: 1px solid #e9e9e9;
  padding: 10px 20px;
  text-align: right;
  border-radius: 0 0 5px 5px;
}

.rc-dialog-zoom-appear,
.rc-dialog-zoom-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.rc-dialog-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.rc-dialog-zoom-appear.rc-dialog-zoom-appear-active,
.rc-dialog-zoom-enter.rc-dialog-zoom-enter-active {
  animation-name: rcDialogZoomIn;
  animation-play-state: running;
}

.rc-dialog-zoom-leave.rc-dialog-zoom-leave-active {
  animation-name: rcDialogZoomOut;
  animation-play-state: running;
}

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media (min-width: 768px) {
  .rc-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

.rc-dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050;
}

.rc-dialog-mask-hidden {
  display: none;
}

.rc-dialog-fade-appear,
.rc-dialog-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-dialog-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-dialog-fade-appear.rc-dialog-fade-appear-active,
.rc-dialog-fade-enter.rc-dialog-fade-enter-active {
  animation-name: rcDialogFadeIn;
  animation-play-state: running;
}

.rc-dialog-fade-leave.rc-dialog-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

* {
  box-sizing: border-box;
}

.rc-select {
  display: inline-block;
  font-size: 12px;
  width: 100px;
  position: relative;
}

.rc-select-disabled,
.rc-select-disabled input {
  cursor: not-allowed;
}

.rc-select-disabled .rc-select-selector {
  opacity: 0.3;
}

.rc-select-show-arrow .rc-select-arrow-icon::after {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  border: 5px solid transparent;
  transform: translateY(5px);
}

.rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::after {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  transform: none;
  margin-top: 4px;
  animation: rcSelectLoadingIcon 0.5s infinite;
}

.rc-select .rc-select-selection-placeholder {
  opacity: 0.4;
  pointer-events: none;
}

.rc-select .rc-select-selection-search-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.rc-select .rc-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.rc-select-single .rc-select-selector {
  display: flex;
  position: relative;
}

.rc-select-single .rc-select-selector .rc-select-selection-search {
  width: 100%;
}

.rc-select-single .rc-select-selector .rc-select-selection-search-input {
  width: 100%;
}

.rc-select-single .rc-select-selector .rc-select-selection-item,
.rc-select-single .rc-select-selector .rc-select-selection-placeholder {
  position: absolute;
  top: 1px;
  left: 3px;
  pointer-events: none;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-search-input {
  border: none;
  outline: none;
  background: rgba(255, 0, 0, 0.2);
  width: 100%;
}

.rc-select-focused .rc-select-selector {
  border-color: $blue-normal !important;
}

.rc-select-multiple .rc-select-selector {
  display: flex;
  flex-wrap: wrap;
  padding: 1px;
  border: 1px solid #000;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-search-input,
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  padding: 1px;

  /* font-family: system-ui; */
}

.rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
  padding: 1px;
  border: 1px solid #000;
}

.rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
  border: none;
  outline: none;
  background: rgba(255, 0, 0, 0.2);
  width: 100%;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-item {
  flex: none;
  background: #bbb;
  border-radius: 4px;
  margin-right: 2px;
  padding: 0 8px;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-overflow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-overflow-item {
  flex: none;
  max-width: 100%;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-search {
  position: relative;
  max-width: 100%;
}

.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  z-index: 999;
  white-space: nowrap;
  position: unset;
  left: 0;
  top: 0;
  visibility: hidden;
}

.rc-select-allow-clear.rc-select-multiple .rc-select-selector {
  padding-right: 20px;
}

.rc-select-allow-clear .rc-select-clear {
  position: absolute;
  right: 20px;
  top: 0;
}

.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
  padding-right: 0;
}

.rc-select-show-arrow .rc-select-arrow {
  pointer-events: none;
  position: absolute;
  right: 5px;
  top: 0;
}

.rc-select-dropdown {
  border: 1px solid green;
  min-height: 100px;
  position: absolute;
  background: #fff;
}

.rc-select-dropdown-hidden {
  display: none;
}

.rc-select-item {
  font-size: 16px;
  line-height: 1.5;
  padding: 4px 16px;
}

.rc-select-item-group {
  color: $light-grey;
  font-weight: 700;
  font-size: 80%;
}

.rc-select-item-option {
  position: relative;
}

.rc-select-item-option-grouped {
  padding-left: 24px;
}

.rc-select-item-option .rc-select-item-option-state {
  position: absolute;
  right: 0;
  top: 4px;
  pointer-events: none;
}

.rc-select-item-option-active {
  background: green;
}

.rc-select-item-option-disabled {
  color: $light-grey;
}

.rc-select-item-empty {
  text-align: center;
  color: $light-grey;
}

.rc-select-selection__choice-zoom {
  transition: all 0.3s;
}

.rc-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
}

.rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
  opacity: 1;
  transform: scale(1);
}

.rc-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
}

.rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
  opacity: 0;
  transform: scale(0.5);
}

.rc-tree-select-selection__choice-zoom {
  transition: all 0.3s;
}

.rc-tree-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
}

.rc-tree-select-selection__choice-zoom-appear.rc-tree-select-selection__choice-zoom-appear-active {
  opacity: 1;
  transform: scale(1);
}

.rc-tree-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
}

.rc-tree-select-selection__choice-zoom-leave.rc-tree-select-selection__choice-zoom-leave-active {
  opacity: 0;
  transform: scale(0.5);
}

.rc-select-dropdown-slide-up-appear,
.rc-select-dropdown-slide-up-enter {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.rc-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }

  to {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }

  to {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }
}

@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.markdown {
  color: $dark-blue;
  font-size: 15px;
  line-height: 1.60625;
}

[data-prefers-color=dark] .markdown {
  color: $dark-blue;
}

.markdown:not(:first-child):empty {
  min-height: 32px;
}
