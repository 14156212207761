@import '../../styles/KrTheme.scss';

.dropDownEmptyListWrap {
  background-color: $white;
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: $dark-grey;
  padding-left: 24px;
}
