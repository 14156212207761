@import "../../styles/theme";

.badgeBase {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: fit-content;

  @extend %font;

  outline: none;
  cursor: pointer;
  background-color: $blue-normal;
  border-radius: $border-radius-24;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  justify-content: space-between;
  height: auto;
  padding: 12px 22px 12px 22px;
}

.badgeEffects {
  &:hover {
    background-color: $blue-hover;
  }

  &:active {
    background-color: $blue-active;
  }
}

.tag {
  justify-content: center;
}

.chip {
  justify-content: space-between;
}

.disabled {
  background-color: $blue-disabled;
  padding-right: 22px;
}

.icon {
  outline: none;
}

.close {
  outline: none;
  width: 16px;
  height: 16px;
}

.add {
  outline: none;
  width: 16px;
  height: 16px;
}

.badgeText {
  padding-right: 22px;
}
