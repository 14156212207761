@import "../../../../styles/theme";

.wrapper {
  display: flex;
  width: 100%;
  max-width: 878px;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 52px;
  justify-content: space-between;
  transition: height 0.3s ease-in;
  overflow: hidden;
}

.wrapperMobile {
  flex-direction: column;
}

.wrapperHide {
  height: 0;
  padding-bottom: 0;
}

.descriptionWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title {
  margin: 0;
  font-family: inherit;
  padding-bottom: 30px;
  max-width: 505px;
}

.description {
  @include setFont($weight: bold);

  font-family: inherit;
  max-width: 568px;
  white-space: pre-line;
}

.descriptionLink {
  cursor: pointer;
  width: fit-content;
  padding-top: 4px;
}

.icon {
  margin-right: 10px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 50px;
}

.buttonsWrapperMobile {
  margin-top: 30px;
  align-items: flex-start;
  margin-left: 0;
}

.headerButton {
  margin-top: 7px;
}

.cancelButton {
  padding-top: 27px;
}

.stepBarWrapper {
  max-width: $form-max-width;
  width: 100%;
  display: inline-flex;
  margin-bottom: 70px;
}

.cancelingWrapper {
  display: flex;
  flex-direction: column;
  padding: 27px 24px 0 0;
}

.cancelingMessage {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: auto;
  padding-bottom: 12px;

  @media (max-width: $breakpoint-small) {
    padding-top: 10px;
    margin-left: 0;
  }
}

.cancellingButtons {
  display: flex;
  justify-content: flex-end;

  & > *:first-child {
    margin-right: 57px;
  }
}

.bold {
  font-weight: bold;
}
