@import '../../styles/KrTheme.scss';

.dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $blue-light;
  background-image: url('./icons/kebab.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 1px solid $blue-normal;
  outline: none;
  cursor: pointer;
  transform: rotate(90deg);

  &:hover {
    border: 1px solid $blue_03;
  }

  &:active {
    border: 1px solid $blue-active;
  }
}
