@import "../../styles/theme";

$borderRadius: 100%;

.wrapper {
  position: relative;
  width: min-content;
}

.root {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.badge {
  background-color: $badge-background;
  border: none;
  -moz-border-radius: $borderRadius;
  -webkit-border-radius: $borderRadius;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-family: inherit;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  outline: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
