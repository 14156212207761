@import '../../../styles/KrTheme.scss';

.container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 16px;
}

.selectionWrap {
  margin-right: 4px;
  margin-bottom: 4px;
}

.buttonAll {
  font-size: 16px;
  line-height: 24px;
  padding: 0 22px;
}
