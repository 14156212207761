/* stylelint-disable no-descending-specificity */
@import '../../styles/theme';

// templates and mixins
@mixin iconVariantPaint($color: $blue-normal) {
  color: $color;

  path {
    &[fill*="#"] {
      fill: $color;
    }

    &[stroke*="#"] {
      stroke: $color;
    }
  }
}

@mixin effects($type) {
  &:hover {
    @if ($type == 'icon') {
      @include iconVariantPaint($blue-hover);
    }
  }

  &:active {
    @if ($type == 'icon') {
      @include iconVariantPaint($blue-active);
    }
  }
}

%fontSettings {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 24px;
}

%contained {
  @extend %fontSettings;

  @include effects('contained');

  background-color: $blue-normal;
  border-radius: inherit;
  color: $white;
  display: flex;
  justify-content: center;
}

// button types
.atom {
  @extend %font;

  border: none;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  width: fit-content;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    transition: all 0.1s linear;
  }

  &:disabled {
    cursor: no-drop;

    %contained {
      background-color: $blue-disabled;
    }

    .icon {
      @include iconVariantPaint($blue-disabled);
    }
  }

  &.loading {
    &:disabled {
      %contained {
        background-color: $blue-normal;
      }
    }
  }
}

.blueLoader {
  & div::after {
    background: $blue-normal;
  }
}

.whiteLoader {
  & div::after {
    background: $white;
  }
}

.icon {
  @extend %fontSettings;

  @include iconVariantPaint();
  @include effects('icon');

  align-items: center;
  display: flex;

  span {
    margin-left: 13px;
  }
}

// storybook
.wrapper {
  display: flex;
  flex-direction: column;
}

.blue {
  background-color: $blue-normal;
}
