@import "../../styles/theme";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: normal;
  font-size: 12px;
  color: $dark;
}

.longContainer {
  max-width: 860px;
}

.hasDrag {
  background: $background;
  border: 2px solid $secondary;
}

.fluidContainer {
  width: 100%;
}

.inputContainer {
  display: flex;
  width: 100%;
  height: 24px; // For IE

  &.inputContainerEmpty {
    height: auto; // For IE
  }

  & label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;
  }

  & svg {
    width: 16px;
    height: auto;
    margin: 0 10px 0 0;
  }
}

.link {
  color: $blue;
  cursor: pointer;
}

.linkGrey {
  color: $primary-text;
}

.input {
  display: none !important;
}

.trashIcon {
  display: flex;
  cursor: pointer;
  align-items: flex-end;
}

.fileDesk {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.fileContainer {
  position: relative;
  width: auto;
  max-width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: inherit;
  background-color: transparent;
  min-height: 56px;
  margin: 12px 0;
}

.fileItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 0 6.5px 0 8px;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
}

.fileInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  text-decoration: none;
}

.fileName {
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  font-family: inherit;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    color: $blue;
  }

  @media (max-width: 430px) {
    max-width: 100px;
  }
}

.fileSize {
  font-weight: 500;
  text-align: right;
  color: #7a7a7c;
  font-family: inherit;
  font-size: 12px;
  line-height: 16px;
}

.error {
  color: $error-text;
  font-weight: 500;
  font-family: inherit;
  font-size: 12px;
  line-height: 16px;
}

.errorMaxSize,
.errorMaxNumber {
  color: $error-text;
  padding-top: 10px;
}

.attachIcon {
  align-self: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  padding: 4px 6px;
  border: none;
  font: inherit;
  color: inherit;
  outline: none;
  background-color: transparent;

  &_cursorDefault {
    cursor: default;
  }
}

.deleteIcon {
  position: absolute;
  right: -28px;
  top: 0;
}

.printIcon {
  position: absolute;
  right: -56px;
  top: 0;
}

.progressBarWrapper {
  width: 100%;
  margin-bottom: 4px;
}

.addButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
