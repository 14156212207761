@import "../../styles/theme";

.stepBarWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 32px;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.atom {
  @include setFont($color: $blue-normal);

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 16px 0 16px 0;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  box-sizing: border-box;

  $transition: 0.13s linear;

  transition: color $transition, background-color $transition, text-decoration $transition;

  &:hover {
    background-color: $blue-hover;
    text-decoration: underline $white;
    color: $white;
  }

  &:active {
    background-color: $blue-active;
    text-decoration: underline $white;
    color: $white;
  }

  &.passed {
    background-color: $blue-normal;
    color: $white;
  }

  &.current {
    background-color: $blue-light;
    text-decoration: underline $white;
    color: $white;
  }

  &:hover > span {
    color: $white;
  }

  &:active > span {
    color: $white;
  }

  &:hover > svg > path {
    stroke: $white;
  }

  &:active > svg > path {
    stroke: $white;
  }
}

.passed {
  background-color: $blue-normal;
  color: $white;
}

.current {
  background-color: $blue-light;
}

.next {
  background-color: $white;
  color: $blue-normal;
  text-decoration: none;
}

.text {
  display: block;
  width: 65px;
  border-color: transparent;
}

.textCurrent {
  color: $blue-normal;

  &:hover {
    color: $white;
  }
}

.textPassed {
  color: $white;
}

.disabled {
  cursor: not-allowed;
}

.wrapperStepsDropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wrapperDropdown {
  max-width: 220px;
  width: 100%;
  min-width: 137px;
  margin-right: 5px;
}
