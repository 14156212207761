@import '../../styles/KrTheme.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  border: none;
  border-radius: 100px;
  outline: none;
  color: $white;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;

  &.inline {
    display: inline-flex;
  }

  &.fill {
    width: 100%;
  }

  &.fitContent {
    width: fit-content;
  }

  &[disabled] {
    pointer-events: none;
    cursor: default;
  }

  &.default {
    background-color: $blue-normal;

    &:hover {
      background-color: darken($blue-normal, 4%);
    }

    &:active {
      background-color: $blue-active;
    }

    &[disabled] {
      background: $blue-disabled;
    }
  }

  &.light {
    background: #fff;
    color: $blue-normal;
    border: 1px solid $blue-normal;

    &:hover {
      color: $blue_03;
      border-color: $blue_03;
    }

    &[disabled] {
      color: $blue-disabled;
      background: #fff;
      border-color: $blue-disabled;
    }
  }

  &.error {
    background: $error;
    border: 1px solid $error;

    &:hover {
      background: $red_03;
    }

    &[disabled] {
      background: $errorLight;
      border-color: $errorLight;
    }
  }

  &.link {
    padding: 0;
    color: $blue-normal;
    background-color: transparent;

    &:hover {
      color: $blue_03;
      background: none;
    }

    &:active {
      color: $blue-active;
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $blue-normal;
  border-radius: 100px;
  cursor: default;
}
