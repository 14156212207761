.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.modalWrapper {
  display: block;
}

div.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.2);
}

div.modalDialog {
  box-shadow: 0 4px 16px rgba(32, 28, 77, 0.16);
  border-radius: 4px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  max-height: 40%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;

  @media (max-width: 900px) {
    width: 70%;
  }

  @media (max-width: 576px) {
    width: 90%;
  }
}

div.modalHeader {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px;
  position: relative;
}

div.modalContent {
  flex: 1;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 0 5px;
  overflow-x: hidden;
  overflow-y: auto;
}

div.modalActions {
  box-sizing: border-box;
  padding: 5px;
  align-self: flex-end;
  display: flex;

  @media (max-width: 350px) {
    flex-direction: column-reverse;
    align-self: center;
    align-items: flex-end;
  }
}

button.closeIcon {
  background-color: #fff;
  border: none;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  outline: none;
  box-sizing: border-box;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
