.radioWrapper {
  margin: 0 0 16px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.error {
  margin-bottom: 16px;
}

.noCryptoBlock {
  & > div {
    margin-top: 4px;
  }

  & > div:first-child {
    margin-bottom: 16px;
  }
}

.loader {
  height: 100px;
}

.blockTitle {
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}
