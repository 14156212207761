@import '../../styles/KrTheme.scss';

$sizeCheckbox: 16px;
$labelDistance: 48px;
$checkBoxBaseColor: #d5dceb;
$checkBoxHover: #99a7f9;

%disabled {
  border-color: $checkBoxBaseColor;
  color: $checkBoxBaseColor;
  background: $checkBoxBaseColor;
  cursor: no-drop;
}

.label {
  font-size: 14px;
}

.container {
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @extend %font;

  .label {
    margin-left: 8px;
    order: 1;
    font-size: 16px;
    line-height: 24px;
  }

  .checked {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background: transparent url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNzg5MzkgMC4yNzc1NjlDOC4wODgxNCAwLjYyNjMzMiA4LjA2NjQ1IDEuMTY4NTUgNy43NDA5NCAxLjQ4ODY0TDMuMzgyNjYgNS43NzQzNUMzLjA3NjQzIDYuMDc1NDggMi42MDU5OSA2LjA3NTE4IDIuMzAwMDkgNS43NzM2N0wwLjI1ODM3NyAzLjc2MTI1Qy0wLjA2Njc4NjQgMy40NDA3NSAtMC4wODc4ODk2IDIuODk4NTEgMC4yMTEyNDIgMi41NTAxMkMwLjUxMDM3NCAyLjIwMTczIDEuMDE2NDcgMi4xNzkxMiAxLjM0MTYzIDIuNDk5NjJMMi44NDIzNSAzLjk3ODgxTDYuNjU5MDYgMC4yMjU2NTZDNi45ODQ1NyAtMC4wOTQ0MzY4IDcuNDkwNjQgLTAuMDcxMTk0NSA3Ljc4OTM5IDAuMjc3NTY5WiIgZmlsbD0iIzYxNzdGRiIvPgo8L3N2Zz4K') no-repeat 50% 50%;
  }
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.labelWrapper {
  position: relative;
  display: inline-flex;
  order: 1;
  align-self: flex-start;
}

.descriptionWrapper {
  display: inline-flex;
  align-self: flex-start;
  order: 2;
  min-width: 14px;
  width: 14px;
  height: 24px;
  margin-left: 12px;
  color: $dark-grey;
}

.descriptionLeft {
  order: 0;
  margin-left: 0;
  margin-right: 3px;
}

.checkMark {
  display: inline-flex;
  position: relative;
  order: 1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 4px;
  left: 0;
  border: 1px solid $checkBoxBaseColor;
  border-radius: 2px;
  background-color: $white;
  min-width: $sizeCheckbox;
  height: $sizeCheckbox;
  width: $sizeCheckbox;

  &.error {
    border-color: $error !important;

    & > .checked {
      background: transparent url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.78939 0.277569C8.08814 0.626332 8.06645 1.16855 7.74094 1.48864L3.38266 5.77435C3.07643 6.07548 2.60599 6.07518 2.30009 5.77367L0.258377 3.76125C-0.0667864 3.44075 -0.0878896 2.89851 0.211242 2.55012C0.510374 2.20173 1.01647 2.17912 1.34163 2.49962L2.84235 3.97881L6.65906 0.225656C6.98457 -0.0944368 7.49064 -0.0711945 7.78939 0.277569Z' fill='%23DB4444'/%3E%3C/svg%3E%0A") no-repeat 50% 50% !important;
    }
  }

  &:hover {
    border-color: $checkBoxHover;

    & > .checked {
      border-color: $checkBoxHover;
    }
  }

  &.disabled {
    @extend %disabled;

    & > .checked {
      border-color: $checkBoxBaseColor;
    }

    &:hover {
      @extend %disabled;

      & > .checked {
        border-color: $checkBoxBaseColor;
      }
    }
  }
}

input[type="checkbox"]:focus + .checkMark {
  border: 1px solid $checkBoxHover;
}

input[type="checkbox"] {
  &.disabledInput {
    &:focus + div {
      border-color: $checkBoxBaseColor;
    }
  }
}

.typeStar {
  padding: 0;

  &:not(.checked) svg path {
    fill: $star-grey;
  }

  &:hover,
  &.checked {
    background-color: initial;
  }

  .starWrapper {
    outline: none;
  }
}

.typeSquare {
  display: flex;
  position: relative;
  order: 1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 4px;
  left: 0;
  border: 1px solid #d5dceb;
  border-radius: 2px;
  background-color: #fff;
  min-width: 16px;
  height: 16px;
  width: 16px;
  padding: 0;

  &:not(.checked) svg path {
    fill: $star-grey;
  }

  &:hover,
  &.checked {
    background-color: initial;
  }

  .starWrapper {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
}

.containerColumn {
  flex-direction: column;
}

.sameStyles {
  color: $blue-hover;
}
