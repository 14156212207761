@import '../../styles/KrTheme.scss';

.container {
  position: relative;
  outline: none;
}

.menu {
  position: absolute;
  display: block;
  top: 44px;
  min-width: 262px;
  max-width: 500px;
  width: max-content;
  margin: 0;
  padding: 0;
  background-color: $white;
  border-radius: 2px;
  box-shadow: $drop-shadow;
  z-index: 2;

  &.left {
    left: 40px;
    transform: translateX(-100%);
  }

  &.right {
    left: 0;
  }
}

.option {
  position: relative;
  padding: 16px 24px;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $blue-light;
  }

  &.disabled {
    color: $dark-grey;
    pointer-events: none;
    cursor: initial;

    &:hover {
      background-color: initial;
    }
  }

  &.leftIcon {
    padding-left: 56px;
  }

  &.primary {
    color: $blue-normal;
  }

  &.warn {
    color: $error;

    &:hover {
      background: $red_02;
    }
  }
}

.inputWrapIcon {
  position: absolute;
  align-items: center;
  top: 50%;
  left: 24px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  background-repeat: no-repeat;

  &.download {
    background-image: url('../../images/icons/download.svg');
  }
}
