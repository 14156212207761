@import '../../styles/KrTheme.scss';

.container {
  background-color: $white;
  border: 1px solid $white;
  max-width: 904px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.containerRound {
  border-radius: $border-radius-32;
  background-color: $disabled;
}

.containerChecked {
  border: 1px solid $blue-normal;
  background-color: $white;
}

.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 64px 0 72px;
  cursor: pointer;
  background-color: $blue-light;
  outline: none;
}

.headerBase {
  min-height: 104px;
}

.headerRound {
  min-height: 88px;
  padding: 31px 38px 33px 48px;
  border-radius: $border-radius-32;
  background-color: $disabled;
}

.headerRoundTop {
  min-height: 88px;
  padding: 31px 68px 43px 48px;
  border-radius: $border-radius-32;
  background-color: $disabled;
}

.headerChecked {
  padding: 0 38px 0 24px;
  min-height: 88px;
  background-color: $white;
}

.headerFilled {
  background-color: $lightgreen-background;
}

.checkBoxWrapper {
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 678px) {
    &.checkBoxWrapperExpanded span {
      white-space: normal;
      overflow: unset;
    }
  }
}

.counterText {
  white-space: nowrap;
  width: fit-content;
  margin-right: 29px;
}

.topTitle {
  margin-bottom: 8px;
}

.bottomTitle {
  margin-top: 6px;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.headerWrapper {
  min-width: 200px;
  display: flex;
  flex-direction: column;

  & > p:first-child {
    margin: 0 0 10px 0;
  }

  & > p:last-child {
    margin: 5px 0 0 0;
  }
}

.checkbox {
  margin-right: 24px;
}

.headerFlat {
  background-color: $white;
  min-height: 80px;
  padding: 0;
}

.expansionContent {
  transition: height 0.4s ease-out;
  height: auto;
}

.hiddenOverflow {
  overflow: hidden;
}

.boxShadow {
  box-shadow: $kr-expansion-panel-box-shadow-color;
}

.toggleChevron {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}

.chevron {
  transition: transform 0.2s ease-in-out;
}

.footer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 72px;
  padding-bottom: 39px;
  outline: none;
}

.wrapperPoint {
  display: flex;
  align-items: center;
}

.point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 14px;
  background-color: $error;
}
