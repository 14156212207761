@import "../../styles/theme";

.notificationWrapper {
  width: 100%;

  @include to($break-768) {
    padding-right: 24px;
    padding-left: 24px;
  }

  .titleExpansionPanel {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $muted-text-color;
  }

  .iconSearchWrapper {
    outline: none;
    cursor: pointer;
  }

  .badgeWrapper {
    margin: 8px 0 0 1px;
  }

  .dateWrapperTest {
    display: flex;
    flex-direction: column;
  }

  .dateWrapper {
    width: 280px;

    @include to($break-1200) {
      width: 100%;
    }
  }

  svg {
    margin: 0;
  }

  h4 {
    padding-right: 22px;
  }

  .markAllRead {
    margin-top: 40px;

    @include to($break-1400) {
      margin-bottom: 10px;
    }
  }

  .linkWrapper {
    margin-bottom: 19px;
  }

  .activityWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 900px;

    svg {
      margin: 0 20px 2px 0;
    }
  }

  .eventsWrapper {
    background-color: $white;
    padding: 1px 75px 38px 32px;
  }

  .textExpansion {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $black-text;
    margin: 0;
  }

  .textLink {
    text-decoration: underline;
    color: #3e76f9;
    cursor: pointer;
  }

  .search {
    display: flex;
    justify-content: space-between;
  }
}

.hasNotifications {
  cursor: pointer;

  & > div button {
    // override inline styles
    cursor: pointer !important;
  }
}

.manyNotifications {
  & > div button > span {
    font-size: 8px;
    line-height: 15px;
  }
}

.notificationBadge {
  font-size: 10px;
  outline: none;
}

.dummyBlock {
  width: 0;
}
