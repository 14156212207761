@import '../../../styles/KrTheme.scss';

$paddingLeft: 24px;
$paddingAfterIcon: 63px;
$lineHeight: 24px;
$input-height: 32px;
$calendarTopPosition: $input-height + 12px;
$topPositionLabel: calc(($input-height - $lineHeight) / 2);
$casual: #a2a1bb;
$active: #15122f;
$textNotActive: #656289;
$error: #db4444;
$disabled: $light-grey;
$accent: #3f75ff;
$range: #f2f5fe;
$grey: #f7f7fb;

//tbody tr:first-child {
//  height: 8px;
//  background-color: red;
//}

tbody tr td p {
  pointer-events: none;
}

table.calendar tbody tr td p {
  color: $black;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  font-family: inherit;
  line-height: 16px;
  justify-content: center;
  align-items: center;
}

.rangeCalendar {
  width: 576px !important;
  display: flex;
  justify-content: space-between;
}

div.calendarWrapper {
  background-color: $white;
  box-sizing: border-box;
  position: absolute;
  top: $calendarTopPosition;
  width: 298px;
  //height: 352px;
  box-shadow: $drop-shadow;
  border-radius: 2px;
  z-index: 100;
  padding: 24px 26px 36px 26px;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &:focus {
    outline: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 6px;

    .text {
      display: flex;
      justify-content: space-between;
      width: fit-content;

      span {
        text-transform: capitalize;
        margin-right: 10px;
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width: 42px;
      margin-right: 4px;

      button.button {
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        cursor: pointer;
        width: 10px;
        height: 24px;
        pointer-events: all;
        background: transparent;
      }

      svg {
        pointer-events: none;
        width: 10px;
        height: 15px;
      }
    }
  }
}

div.positionLeft {
  left: 23px;
}

table.calendar {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  tbody tr {
    td {
      &.tableCell {
        margin: 0 auto;

        & button {
          box-sizing: border-box;
          width: 24px;
          height: 24px;
          padding: 0;
          margin-left: 4px;
          border: 2px solid #fff;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
          font-size: 16px;
          //line-height: 24px;
          color: $active;
          font-family: inherit;
          background: transparent;
          position: relative;

          &::after {
            display: block;
            content: ' ';
            position: absolute;
            top: -5px;
            left: -5px;
          }

          &.disabled {
            color: $disabled;
            pointer-events: none;
            cursor: default;
          }

          &.selected,
          &:hover {
            background-color: $range;
            border-color: $range;
            border-radius: 0;

            &::after {
              border: 2px solid $blue-normal;
              width: calc(100% + 10px);
              height: calc(100% + 10px);
              border-radius: 4px;
            }
          }

          &.selected:hover {
            color: #000;
          }
        }

        &.inRange {
          background-color: $blue-light;
          border-color: $blue-light;
          position: relative;

          &::after {
            content: '';
            width: 100%;
            height: 26px;
            background-color: $blue-light;
            position: absolute;
            top: 0;
            left: -50%;
            z-index: -1;
          }

          &::before {
            content: '';
            width: 100%;
            height: 26px;
            background-color: $blue-light;
            position: absolute;
            top: 0;
            right: -50%;
            z-index: -1;
          }

          button {
            border-color: $blue-light;
          }
        }
      }
    }
  }
}
