@import "../../styles/theme";

$border-radius-block-sms-code: 16px;
$border-radius-block-sms-code-popup: 4px;

.wrapper-block {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.root {
  background-color: $background;
  border-radius: $border-radius-block-sms-code;

  @extend %font;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 484px;
  height: 152px;
  padding: 16px 24px;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 17px;
}

.header {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.button {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: $primary-text;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 20px;
}

.content input {
  background-color: transparent;
  outline: none;
}

.text {
  font-size: 10px;
  line-height: 12px;
}

.footer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.popup {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 262px;
  height: 72px;
  background-color: $main;
  border-radius: $border-radius-block-sms-code-popup;
  padding: 8px;
  display: flex;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  outline: none;
}

.popup::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -20px;
  border: 16px solid transparent;
  border-top: 8px solid $main;
}

.popup-text {
  color: $white;
  font-size: 10px;
  line-height: 12px;
}

.code-send {
  font-size: 10px;
  line-height: 12px;
}

.errorCode {
  font-size: 10px;
  line-height: 12px;
}
