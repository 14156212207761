@import "../../styles/theme";

.badgeRoleWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 24px;
  color: #fff;
  padding: 0 17px;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
  text-align: center;
}

.budgeEmployees {
  margin-top: 33px;
  padding: 0 25px;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.blueLight {
  background-color: $blue-light;
}

.lightGray {
  background-color: $light-grey;
}

.darkGray {
  background-color: $dark-grey;
}

.white {
  background-color: $white;
}

.disabled {
  background-color: $disabled;
}

.darkBlue {
  background-color: $dark-blue;
}

.blueLightText {
  color: $blue-light;
}

.lightGrayText {
  color: $light-grey;
}

.darkGrayText {
  color: $dark-grey;
}

.whiteText {
  color: $white;
}

.disabledText {
  color: $disabled;
}

.darkBlueText {
  color: $dark-blue;
}
