@import "../../../../styles/theme";

.notificationPanelWrapper {
  //margin: 20px 0 0 -32px;
  margin: 20px 0 0 0;
  width: 100%;
  max-width: 832px;

  & .expansionWrapper {
    background-color: $white;

    & > div {
      width: 100%;
      max-width: 800px;
      margin-bottom: 16px;
      background-color: inherit;
      border: 1px solid $blueNormal;
      border-radius: 32px;

      & > div:first-child {
        background-color: inherit;
        padding: 23px 69px 19px 31px;

        @include to($break-768) {
          padding: 23px 37px 26px 24px;
        }
      }

      & > div:nth-child(2) {
        transition: height 0.1s ease-out;
      }
    }

    &.viewed > div {
      background-color: $greyLight;
      border: 1px solid $greyLight;
      border-radius: 32px;
    }
  }
}

.notificationContentWrapper {
  padding: 0 69px 33px 23px;

  @include to($break-768) {
    padding: 0 37px 33px 23px;
  }

  & p {
    word-break: break-word;
  }
}

.notSearchWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -8px;
}

.notSearch {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $black-text;
}

.notFoundNotifications {
  @extend %fontRoboto;

  font-style: normal;
  font-weight: normal;
  padding: 16px 40px 16px;
  color: #ff4120;
}

.link {
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: $blueNormal;
  font-weight: normal;
}

.notificationsInHeader {
  @extend %fontRoboto;

  margin: 0 40px;
  display: flex;
  height: auto;
  font-style: normal;
  font-weight: normal;
  padding: 28px 0 20px 0;
  background-color: #87cefa;

  .notificationIcon {
    flex: 1 1 auto;
    margin-right: 16px;
    max-width: 20px;
  }

  .notificationDescription {
    @extend %fontRoboto;

    flex: 2 1 auto;
    font-style: normal;
    font-weight: normal;
    color: #000;
    font-size: 14px;
    line-height: 20px;

    & > div:nth-child(1) {
      font-size: 14px;
      line-height: 20px;
      color: #000;
    }

    & > div:nth-child(2) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: #7a7a7c;

      & > a {
        text-decoration: underline;
        color: #191970;

        &:visited {
          color: #7a7a7c;
        }
      }
    }
  }

  .notificationDate {
    flex: 0 1 auto;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #000;
    margin-right: 5px;
    margin-left: 5px;
    min-width: 43px;
    text-align: right;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dadada;
  }

  &.notificationReadOverUi {
    background-color: #fff;
  }
}
