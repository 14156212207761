@import '../../styles/theme';

$borderRadius: 12px;
$labelDistance: 16px;
$heightSwitchWrapper: 24px;
$widthSwitchWrapper: $heightSwitchWrapper * 2;
$sizeSwitchCircle: 16px;
$paddingSwitchWrapper: 4px;
$translatedSwitch: $widthSwitchWrapper - $paddingSwitchWrapper * 2 - $sizeSwitchCircle;

.container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @extend %font;

  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  color: $dark-text;
}

.inputWrapper {
  display: flex;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.switch {
  display: flex;
  order: 1;
  box-sizing: border-box;
  align-items: center;
  width: $widthSwitchWrapper;
  height: $heightSwitchWrapper;
  background-color: $blue-light;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: $borderRadius;
  padding: $paddingSwitchWrapper;

  &::before {
    content: "";
    height: $sizeSwitchCircle;
    width: $sizeSwitchCircle;
    background-color: $light-grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover {
    &::before {
      background-color: $blue-hover;
    }
  }
}

div.descriptionStyle {
  font-size: 12px;
  line-height: 16px;
  color: $dark-grey;
  font-weight: normal;
  order: 3;
}

input:checked + .switch::before {
  -webkit-transform: translateX($translatedSwitch);
  -ms-transform: translateX($translatedSwitch);
  transform: translateX($translatedSwitch);
  background-color: $blue-normal;
}

.disableHover {
  &:hover {
    &::before {
      background-color: $light-grey;
    }
  }
}

.label {
  margin-left: $labelDistance;
  order: 2;
}
