@import '../../styles/KrTheme.scss';

.container {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  overflow-y: auto;
  animation: modalAppears 100ms ease-in;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@-moz-document url-prefix() {
  .showScroll {
    right: 17px;
  }
}

.container .container .overlay {
  &.square {
    border-radius: 2px;
  }

  &.rounded {
    border-radius: $borderRadiusBig;
    background-color: transparent;
    box-shadow: 0 0 100vw #00000080 inset;
  }
}

.wrapper {
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 $blue-disabled;
  z-index: 60;

  &.square {
    border-radius: 2px;
  }

  &.rounded {
    border-radius: $borderRadiusBig;
  }

  &.large {
    width: 800px;
  }

  &.medium {
    width: 600px;
  }

  &.small {
    width: 480px;
  }
}

.wrapper .wrapper {
  position: fixed;
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: $blue-normal;

  &:focus {
    outline: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid $blue-disabled;

  .closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    padding: 0;
    cursor: pointer;
  }

  &.headerEmpty {
    padding: 0;
    border: none;

    .closeBtn {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 65;
    }
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
}

@keyframes modalAppears {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
