@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;

  &--process {
    background-color: #6177ff;
  }

  &--changed {
    background-color: #f2831c;
  }

  &--complete {
    background-color: #349c6a;
  }

  &--closed {
    background-color: #9c9d9e;
  }
}

.text {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &--closed {
    color: #7a7a7c;
  }
}
