/* stylelint-disable no-descending-specificity */
@import '../../styles/theme';

$sizeLoader: 20px;

// templates and mixins
@mixin iconVariantPaint($color: $blue-normal) {
  color: $color;

  path {
    &[fill*="#"] {
      fill: $color;
    }

    &[stroke*="#"] {
      stroke: $color;
    }
  }
}

@mixin thirdVariantPaint($color: $blue-normal) {
  border: 1px solid $color;
  color: $color;
}

@mixin textVariantPaint($color: $blue-normal) {
  color: $color;
}

@mixin effects($type) {
  &:hover {
    @if ($type == 'contained') {
      background-color: $blue-hover;
    }

    @else if ($type == 'third') {
      @include thirdVariantPaint($blue-hover);
    }

    @else if ($type == 'icon') {
      @include iconVariantPaint($blue-hover);
    }

    @else if ($type == 'text') {
      @include textVariantPaint($blue-hover);
    }
  }

  &:active {
    @if ($type == 'contained') {
      background-color: $blue-active;
    }

    @else if ($type == 'third') {
      @include thirdVariantPaint($blue-active);
    }

    @else if ($type == 'icon') {
      @include iconVariantPaint($blue-active);
    }

    @else if ($type == 'text') {
      @include textVariantPaint($blue-hover);
    }
  }
}

%fontSettings {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.01em;

  @media (max-width: 450px) {
    font-size: 14px;
  }
}

%contained {
  @extend %fontSettings;

  @include effects('contained');

  background-color: $blue-normal;
  border-radius: inherit;
  color: $white;
  display: flex;
  justify-content: center;
}

// button types
.atom {
  @extend %font;

  border: none;
  -moz-border-radius: 48px;
  -webkit-border-radius: 48px;
  border-radius: 48px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  width: fit-content;
  background-color: transparent;
  height: 42px;

  & > div {
    max-height: 100%;
  }

  div {
    transition: all 0.1s linear;
  }

  &:disabled {
    cursor: no-drop;

    %contained {
      background-color: $blue-disabled;
    }

    .third {
      @include thirdVariantPaint($blue-disabled);
    }

    .text {
      @include textVariantPaint($blue-disabled);
    }

    .icon {
      @include iconVariantPaint($blue-disabled);
    }
  }

  &.loading {
    &:disabled {
      %contained {
        background-color: $blue-normal;
      }
    }
  }
}

.primary {
  @extend %contained;

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 32px;

  span {
    display: flex;
    justify-content: space-between;
    margin-right: 2px;
  }
}

.secondary {
  @extend %contained;

  padding: 12px 24px 12px;
  text-align: center;

  @media (max-width: 450px) {
    padding: 10px 20px 10px;
  }
}

.text {
  font-size: 16px;

  @extend %fontSettings;

  @include textVariantPaint();
  @include effects('text');

  display: flex;
  justify-content: center;
  align-items: center;

  &.delete {
    @include textVariantPaint($error);
  }

  &.delete:hover {
    @include textVariantPaint($errorHover);
  }

  &.delete:active {
    @include textVariantPaint($errorActive);
  }

  &.link {
    font-weight: normal;
    text-decoration: underline;
  }
}

.blueLoader {
  & div::after {
    background: $blue-normal;
  }
}

.whiteLoader {
  & div::after {
    background: $white;
  }
}

.btnLoader {
  width: $sizeLoader;
  height: $sizeLoader;

  & div {
    transform-origin: 12px 10px;
  }
}

.third {
  @extend %fontSettings;

  @include thirdVariantPaint();
  @include effects('third');

  border-radius: inherit;
  padding: 12px 24px 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.delete {
    @include thirdVariantPaint($error);
  }
}

.icon {
  @extend %fontSettings;

  @include iconVariantPaint();
  @include effects('icon');

  align-items: center;
  display: flex;

  span {
    margin-left: 13px;
  }
}

// storybook
.wrapper {
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 15px;
  }
}
