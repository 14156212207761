/* stylelint-disable no-descending-specificity */
@import '../../styles/theme';

$paddingHorizontal: 40px;
$heightFilter: 42px;

@mixin setColorWeightFont($color: $blue-normal, $weight: normal) {
  @include setFont(
    16px,
    24px,
    $weight,
    $color
  );
}

@mixin setBackground($color: $white) {
  background: $color;
}

.filters {
  @include setBackground($white);

  display: inline-flex;
  border-radius: $border-radius-32;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;

  .tab {
    padding: 0 4px;
    cursor: pointer;
    outline: 0;
    box-sizing: border-box;
    height: $heightFilter;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .labelDefault {
      @include setColorWeightFont($blue-normal);

      &.labelDisabled {
        @include setColorWeightFont($light-grey);
      }
    }

    &.disabled {
      cursor: default;
    }

    &.active {
      @include setBackground($blue-light);

      .labelDefault {
        font-weight: bold;
      }
    }

    &.clicked {
      @include setBackground($blue-active);

      .labelDefault {
        @include setColorWeightFont($white, bold);
      }
    }

    &:first-child {
      border-bottom-left-radius: $border-radius-32;
      border-top-left-radius: $border-radius-32;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-32;
      border-top-right-radius: $border-radius-32;
    }

    &:not(.disabled):not(.clicked):hover {
      @include setBackground($blue-hover);

      .labelDefault {
        @include setColorWeightFont($white, bold);
      }
    }

    &.transparentBackground {
      &:first-child {
        border-bottom-left-radius: $border-radius-0;
        border-top-left-radius: $border-radius-0;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius-0;
        border-top-right-radius: $border-radius-0;
      }

      &.active {
        @include setBackground($transparent);
      }

      &.clicked {
        @include setBackground($transparent);

        .labelDefault {
          @include setColorWeightFont($blue-normal, bold);
        }
      }

      &:not(.disabled):not(.clicked):hover {
        @include setBackground($transparent);

        .labelDefault {
          @include setColorWeightFont($blue-normal, bold);
        }
      }
    }
  }

  .notificationWrapper {
    display: inline-block;
    margin-left: 3px;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $error;
  }
}
