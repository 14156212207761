/* stylelint-disable no-descending-specificity */
@import '../../styles/KrTheme.scss';

[class*=FormHeader_wrapper] {
  padding-bottom: 32px !important;
}

[class*=FormHeader_description_] {
  margin-bottom: 32px !important;
}

%extremeText {
  border-radius: 0;
  width: auto;
  max-width: 150px;
  min-width: 120px;
}

@mixin stepStates($background, $border, $text) {
  background: $background;
  border-color: $border;
  color: $text;
}

@mixin stepIndex {
  line-height: 41px;
  font-size: 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 0;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;
  counter-increment: step;
  content: counter(step);
  text-align: center;
  border: 1px solid $blue-normal;
  background-color: #fff;
  display: block;
}

@mixin interSteps {
  content: '';
  position: absolute;
  top: 20px;
  left: 0;
  height: 1px;
  background-color: $blue-normal;
  width: 100%;
}

.container {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  padding: 0 20px;
  counter-reset: step;
}

.step {
  position: relative;
  display: table-cell;
  text-align: center;
  color: $blue-normal;
  font-weight: 500;
  cursor: pointer;
  z-index: 1;

  &::after {
    @include interSteps;
  }

  &::before {
    @include stepIndex;
  }

  &:first-child .stepName {
    left: -20px;
    text-align: left;
    max-width: 260px;
  }

  &:last-child {
    width: 0;

    & .stepName {
      @extend %extremeText;

      left: -75px;
      text-align: right;
      max-width: 60px;

      & > div {
        max-width: 100%;
      }
    }
  }

  &.disabled {
    &::before {
      @include stepStates($white, $blue-disabled, $blue-disabled);
    }
  }

  & > .stepName {
    min-width: 95px;
    line-height: 13px;

    & > div > div:nth-child(2) {
      width: 200%;
    }
  }
}

.stepName {
  display: block;
  position: relative;
  width: 64%;
  left: -32%;
  padding-top: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color: black;
}

.current {
  .stepName {
    font-weight: 500;
  }

  &::before {
    @include stepStates($blue-normal, $blue-normal, $white);
  }
}

.passed {
  &::before {
    @include stepStates($blue-normal, $blue-normal, $white);

    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMTg5MTYxIDMuNzE1MjhDMC40NDEzMSAzLjQ5ODM2IDAuODUwMTI1IDMuNDk4MzYgMS4xMDIyNyAzLjcxNTI4TDQuMDYyODggNi40Mjg5N0wxMC44OTc4IDAuMTYyNjg2QzExLjE0OTkgLTAuMDU0MjI4NiAxMS41NTg3IC0wLjA1NDIyODYgMTEuODEwOSAwLjE2MjY4NkMxMi4wNjMgMC4zNzk2IDEyLjA2MyAwLjczMTI4OCAxMS44MTA5IDAuOTQ4MjAzTDQuMDYyODggOEwwLjE4OTE2MSA0LjUwMDc5Qy0wLjA2Mjk4ODYgNC4yODM4OCAtMC4wNjI5ODg1IDMuOTMyMTkgMC4xODkxNjEgMy43MTUyOFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
  }

  &.disabled {
    &::before {
      @include stepStates($blue-disabled, $blue-disabled, $blue-disabled);
    }
  }

  .stepName {
    color: $dark-grey;
  }
}

.failed {
  &::before {
    @include stepStates($error, $error, $white);

    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjIxMDg4MyAwLjIxMDg4M0MwLjQ5MjA2MSAtMC4wNzAyOTQ0IDAuOTQ3OTM5IC0wLjA3MDI5NDQgMS4yMjkxMiAwLjIxMDg4M0w2IDQuOTgxNzdMMTAuNzcwOSAwLjIxMDg4M0MxMS4wNTIxIC0wLjA3MDI5NDQgMTEuNTA3OSAtMC4wNzAyOTQ0IDExLjc4OTEgMC4yMTA4ODNDMTIuMDcwMyAwLjQ5MjA2MSAxMi4wNzAzIDAuOTQ3OTM5IDExLjc4OTEgMS4yMjkxMkw3LjAxODIzIDZMMTEuNzg5MSAxMC43NzA5QzEyLjA3MDMgMTEuMDUyMSAxMi4wNzAzIDExLjUwNzkgMTEuNzg5MSAxMS43ODkxQzExLjUwNzkgMTIuMDcwMyAxMS4wNTIxIDEyLjA3MDMgMTAuNzcwOSAxMS43ODkxTDYgNy4wMTgyM0wxLjIyOTEyIDExLjc4OTFDMC45NDc5MzkgMTIuMDcwMyAwLjQ5MjA2MSAxMi4wNzAzIDAuMjEwODgzIDExLjc4OTFDLTAuMDcwMjk0NCAxMS41MDc5IC0wLjA3MDI5NDQgMTEuMDUyMSAwLjIxMDg4MyAxMC43NzA5TDQuOTgxNzcgNkwwLjIxMDg4MyAxLjIyOTEyQy0wLjA3MDI5NDQgMC45NDc5MzkgLTAuMDcwMjk0NCAwLjQ5MjA2MSAwLjIxMDg4MyAwLjIxMDg4M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
  }

  &.disabled {
    &::before {
      @include stepStates($blue-disabled, $blue-disabled, $blue-disabled);
    }
  }

  .stepName {
    color: $error;
  }
}

.disabled {
  cursor: default;

  &::before {
    @include stepStates($white, $blue-disabled, $blue-disabled);
  }

  .stepName {
    color: $light-grey;
  }
}
