
.wrapper {
  position: relative;
  width: 100%;
}

.shortcutWrapper {
  outline: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
}

.link {
  display: inline-block;
  color: #6177ff;
  text-decoration: underline;
}

.title {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #656289;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #656289;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
}

.info {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #15122f;
}

.expandedWrapper {
  min-width: 100%;
  max-width: 608px;
  min-height: 100%;
  padding: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(32, 28, 77, 0.16);
  border-radius: 8px;
  z-index: 10;
}

.expandedHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.closeButton {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #656289;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}

.expandContent {
  width: 100%;
  box-sizing: border-box;
}

.expandFullText {
  margin: 0 15px 0 0;
}
