@import "../../styles/theme";

.wrapper {
  box-sizing: border-box;
  position: relative;
  min-height: $input-height;
  height: auto;
  width: 516px;
  background-color: transparent;

  &.wrapperWithPreLabel {
    min-height: $input-height + 24px;
  }

  .styleWithPreLabel {
    top: calc($input-height / 2) + 24px !important;
  }
}

.inputWrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: $input-height;
  width: 100%;
  border: 1px solid $blue-light;
  border-radius: 28px;

  &.inactive {
    border-color: $blue-light;
    background-color: $blue-light;
    transition: border-color 0.25s ease, background-color 0.25s ease;
  }

  &.active {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-color: $blue-hover;
    background-color: $white;
    transition: border-color 0.25s ease, background-color 0.25s ease;
  }

  &.errorBorderStyles {
    border-color: $error !important;
  }

  &.activeWithPreLabel {
    top: 24px;
  }
}

.tooltip {
  color: $blue-normal;

  svg {
    margin-bottom: 2px;
    color: #6177ff;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8.5px;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;

  & svg {
    pointer-events: none;

    & path {
      pointer-events: none;
    }
  }

  &.search {
    left: 24px;

    &.show svg {
      width: 16px;
      height: 16px;
    }
  }

  &.close {
    right: 24px;

    &.left {
      left: 24px;
    }

    &.show svg {
      width: 11px;
      height: 11px;
    }
  }

  &.search,
  &.close {
    &.show {
      pointer-events: all;
      cursor: pointer;

      & svg {
        transition: all 0.25s ease-out;
      }
    }

    &.hide {
      pointer-events: none;
      cursor: default;

      & svg {
        width: 0;
        height: 0;
        transition: all 0.25s ease-out;
      }
    }
  }
}

.input {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  height: $input-height;
  outline: none;
  background-color: transparent;
  border: none;
  border-radius: 28px;
  padding: 0 33px 0 64px;
  font-size: 16px;

  &.focusedOrFullfilled {
    padding: 0 64px 0 33px;
  }

  &.withoutIconSearch {
    padding-left: 33px;
  }

  &[class*='regFIO'] {
    padding: 0 24px;
  }
}

.errorMessage {
  display: block;
  margin-top: 7px;
  margin-left: 24px;
}

.labelWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  &.small,
  &.smallSquare {
    margin-bottom: 0;
  }

  .label {
    @include setFont($size: 14px, $lineHeight: 20px, $weight: normal, $color: $dark-grey);

    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
    display: inline-block;

    &.disabled {
      color: $light-grey;
    }
  }
}

.tooltipLabel {
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
}
