@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  flex-direction: column;
}

.input {
  display: none;
}

.addFileLink {
  color: $blue-normal;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.file {
  padding: 8px 0;

  & > div {
    width: 100%;
  }
}

.uploaderWrapper {
  &.loadMoreFiles {
    margin-top: 8px;
    order: 1;
  }

  &.hidden {
    display: none;
  }
}

.errorMessage {
  margin-top: 4px;
  font-size: 12px;
  color: $error;
}

.inputContainer {
  display: none;
}
