@import '../../styles/KrTheme.scss';

$paddingLeft: 24px;
$paddingAfterIcon: 63px;
$lineHeight: 24px;
$input-height: 32px;
$errorPadding: 20px;
$topPositionLabel: calc(($input-height - $lineHeight) / 2);
$casual: #7a7a7c;
$active: #15122f;
$textNotActive: #656289;
$error: #db4444;
$disabled: #e3e1f3;
$input-disabled: #eee;
$accent: #3f75ff;
$range: #f2f5fe;
$grey: #f7f7fb;
$borderRadiusRounded: 24px;
$borderRadiusSquared: 2px;

.label {
  @include setFont($size: 14px, $lineHeight: 20px, $weight: normal, $color: $dark-grey);

  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  &.focused {
    //
  }

  &.focusedIconFirst {
    left: $paddingLeft;
  }

  &.hideLabel {
    visibility: hidden;
    transition: none;
  }

  &.disabled {
    color: $light-grey;
  }
}

.readOnly {
  //
}

.labelWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;

  &.small {
    margin-bottom: 0;
  }
}

.labelAfterIcon {
  left: $paddingAfterIcon;
}

.labelBeforeIcon {
  left: $paddingLeft;
}

.labelRequired {
  padding-left: 5px;
  color: $error;
}

.iconLeft {
  margin-right: -50px;
}

.iconRight {
  margin-left: -44px;
}

.indicator {
  position: absolute;
  bottom: 21px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: $casual;

  &.disabled {
    background-color: $disabled;
  }

  &.error {
    background-color: $error;
  }

  &.active {
    bottom: 20px;
    height: 2px;
    background-color: $active;

    &.error {
      background-color: $error;
      bottom: $errorPadding;
    }
  }
}

.message {
  display: block;
  margin-top: 5px;
  width: 100%;
  line-height: 16px;
  font-size: 14px;
  height: 20px;
  color: $error;
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  &.small {
    .iconRight {
      margin-left: -32px;
    }

    .normalCalendarWrapper {
      position: absolute;
      top: -8px;
      width: 100%;
    }
  }

  input {
    width: 100%;
    padding: 0 56px 0 24px;

    &.rounded {
      border-radius: $borderRadiusRounded;
    }

    &.squared {
      border-radius: $borderRadiusSquared;
    }

    &.disabled {
      background: $input-disabled;
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &.small {
    .inputWrapper {
      input {
        height: $input-height;
        padding: 0 8px;
      }
    }

    .iconRight {
      margin-right: 14px;
    }
  }
}

.inputWrapperLeftIcon {
  flex-direction: row-reverse;
}

.tooltip {
  display: flex;
  align-items: center;
  margin-left: auto;
  min-height: 20px;

  svg {
    width: 12px;
    height: 12px;
  }
}

button.icon > svg {
  width: 100%;
  height: 100%;
  pointer-events: none;

  & path {
    fill: $blue-normal;
  }
}

input.inputPositionAfterIcon {
  padding-left: $paddingAfterIcon;
}

.descAttribute {
  margin: 10px 24px;
  font-size: 12px;
  color: $dark-grey;
  cursor: pointer;
}

.sameStyles {
  [class*=wrapper] {
    display: block;

    [class*=label] {
      font-size: 16px;
      display: inline-block;
    }
  }

  [class*=message] {
    margin-left: 0;
    bottom: -24px;
    margin-top: 4px;
    position: absolute;
  }

  [class*=labelBeforeIcon] {
    left: 0;
  }

  [class*=KrTooltip_container] svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: $blue-normal;
    }
  }
}

.errorInput {
  border-color: $error !important;
}

.errorTooltip {
  &.small {
    .inputWrapper {
      input {
        background-color: $red_02;
        border-color: $error;
      }
    }
  }
}

button.icon {
  height: 16px;
  width: 16px;
  padding: 0;
  border: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  pointer-events: all;

  &[disabled] {
    cursor: default;

    svg {
      path {
        fill: $dark-grey;
      }
    }
  }
}

input.input:disabled ~ button.icon {
  pointer-events: none;
  cursor: default;
}
