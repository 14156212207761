@import "../../styles/theme";

.empty-menu-tab-box,
.menu-tab-box {
  display: flex;
  background-color: $brandColor;
  height: $menuHeight;
  padding: 10px 0;
  overflow: hidden;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;

  .logout-button {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    color: $textWhite;
    text-decoration: none;
    font-family: inherit !important;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    padding: 0 $paddingHorizontal;
    height: $menuHeight;
    align-items: center;

    &:hover {
      background-color: $hoverColor;
      cursor: pointer;
    }
  }

  .group-logo-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
  }

  .logo {
    display: flex;
    outline: none;
    height: 77px;

    &-exp {
      width: 70px;
      height: 100%;
      outline: none;
    }

    &-link {
      outline: none;
    }
  }

  .name-user {
    color: $textWhite;
    padding: 0 10px;
  }

  .button-logout {
    color: $textWhite;
    padding: 0 10px;
    text-decoration: none;
  }

  .list {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $textWhite;
    margin: 0;
    padding: 0;

    &-item {
      list-style: none;
      display: flex;
      justify-content: center;
      height: 100%;
      box-sizing: border-box;
      cursor: pointer;

      &-link {
        color: #ffffff;
        text-decoration: none;
        outline: none;
        display: flex;
        justify-content: center;
        padding: 22px $paddingHorizontal;
        box-sizing: border-box;
        cursor: pointer;
        height: $menuHeight;
        align-items: center;
      }

      &-active {
        background-color: $hoverColor;
      }

      &:hover {
        background-color: $hoverColor;
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }

      &-icon {
        margin-right: 9px;
      }
    }
  }

  .group-profile-notification {
    display: flex;
    align-items: center;
  }

  .profile {
    &-not-user {
      &-wrapper-icon {
        background-color: $textWhite;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
      }
    }

    &-user {
      display: flex;
      align-items: center;
      outline: none;
      text-decoration: none;
      height: $menuHeight;
      padding: 0 $paddingHorizontal;

      &:hover {
        background-color: $hoverColor;
        cursor: pointer;
      }

      &-text {
        color: $textWhite;
        padding: 0 10px;
      }

      &-icon {
        width: 80px;
        height: 80px;
      }

      & > svg {
        min-width: 40px;
      }
    }
  }

  .notifications {
    display: flex;
    align-items: center;
    height: $menuHeight;
    padding: 0 $paddingHorizontal;
    margin-right: 8px;

    &:hover {
      background-color: $hoverColor;
      cursor: pointer;
    }

    &-text {
      color: $textWhite;
      padding: 0 10px;
    }

    &-wrapper {
      position: relative;
      padding-top: 6px;
    }

    &-icon {
      width: 30px;
      height: auto;
      padding-top: 10px;
    }

    &-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #E13C39;
      color: #ffffff;
      width: 16px;
      height: 16px;
      text-align: center;
      position: absolute;
      bottom: -9px;
      right: -8px;
      font-size: 10px;
      font-weight: normal;
    }
  }
}
