@import '../../styles/KrTheme.scss';

.DocForSinging {
  display: flex;
  align-items: center;

  &.border {
    padding: 28px 24px 28px 32px;
    border: 1px solid #d5dceb;
    border-radius: 2px;
  }

  &.contract {
    width: 100%;
    height: 124px;
  }

  .exporterName {
    font-size: 16px;
    line-height: 24px;
    color: $blue-normal;
    margin-bottom: 4px;
  }

  .serviceNames {
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey;
  }

  .customButton {
    width: 186px;
  }

  .contractSignedBlock {
    min-width: 85px;
  }

  .marginRight24 {
    margin-right: 24px;
    overflow: hidden;
  }

  .marginRight29 {
    margin-right: 29px;
  }
}
