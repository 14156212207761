@import '../../styles/theme';

$slideImageHeight: 280px;
$bulletsMargin: 24px;
$bulletSize: 8px;

.slideImage {
  width: 100%;
  height: $slideImageHeight;
  background-size: cover;
}

.modalHeader {
  z-index: 1;
}

.slideContent {
  padding: ($bulletsMargin * 2 + $bulletSize) 40px 28px;
}

.sliderBullets {
  position: absolute;
  top: $slideImageHeight + $bulletsMargin;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: $bulletSize;
}

.sliderBullet {
  $gap: 8px;

  display: inline-block;
  height: $bulletSize;
  line-height: $bulletSize;

  &:not(:last-child) {
    margin-right: $gap;
  }

  button {
    display: inline-block;
    width: $bulletSize;
    height: $bulletSize;
    background-color: $blue-disabled;
    border-radius: 50%;
    padding: 0;
    font-size: 0;
    outline: 0;
  }
}

.sliderBulletActive {
  button {
    background-color: $blue-normal;
  }
}

.controls {
  padding: 0 40px 42px;
}

.sliderModalHeader {
  z-index: 1;
}
