@import '../../styles/KrTheme.scss';

.container {
  position: relative;
}

.content {
  padding: 32px;
}

.contentBlock {
  &:first-child {
    margin-bottom: 16px;
  }
}

.contentItem {
  margin-bottom: 16px;

  & > div {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 24px;
  }
}

.radioWrapper {
  margin: 0 0 16px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.blockTitle {
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

.refreshIcon {
  margin-left: 10px;
  width: 24px;
  height: 32px;
  margin-top: 2px;
  cursor: pointer;
  display: inline-block;

  svg {
    transform: rotate(120deg);
    width: 16px;
    height: 16px;
  }
}

.cancelButton {
  margin-right: 16px;
}

.documentContainer {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error {
  margin-bottom: 16px;
}
