@import '../../styles/KrTheme.scss';

$svgSize: 12px;
$optionalTextMargin: 8px;

.wrap {
  width: 100%;
  display: flex;
}

.description {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  &.small,
  &.smallSquare {
    margin-bottom: 0;
  }
}

.normal {
  font-size: 14px;
  line-height: 20px;
}

.small {
  font-size: 14px;
  line-height: 20px;
}

.smallSquare {
  font-size: 14px;
  line-height: 20px;
}

.label {
  color: $dark-grey;
}

.infoTooltipWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-grey;

  svg {
    width: $svgSize;
    height: $svgSize;
  }
}

.rightGroup {
  display: flex;
  align-items: center;
}

.optionalText {
  font-size: 14px;
  line-height: 20px;
  color: $dark-grey;
  margin-right: $optionalTextMargin;

  &WithoutTooltip {
    margin-right: $optionalTextMargin + $svgSize;
  }
}
