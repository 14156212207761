@import "../../styles/theme";

$borderRadius: 32px;

.root {
  @extend %font;

  box-sizing: border-box;
  display: flex;
  border-radius: $borderRadius;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.withCloseIcon {
  height: 40px;
  width: fit-content;
  min-width: 108px;
  justify-content: space-between;
  align-items: center;
  padding: 13px 19px 11px 16px;
}

.withoutCloseIcon {
  height: 40px;
  width: fit-content;
  min-width: 84px;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px 11px 16px;
}

.fourth {
  background: $fourth;
  color: $main;
}

.accent {
  background: $accent;
  color: $white;
}

.secondary {
  background: $secondary;
  color: $white;
}

.fitContent {
  height: fit-content;
  width: fit-content;
}

.borderless {
  color: $secondary;
}

.disabled {
  color: $third;
}

.cursorPointer {
  cursor: pointer;
}

.close {
  cursor: pointer;
  margin-left: 11px;

  &:hover {
    opacity: 0.6;
  }
}
