@import '../../styles/KrTheme.scss';

$marginCaption: 4px;

.errorMessage {
  font-size: 14px;
  line-height: 20px;
  color: $error;
  margin-top: $marginCaption - 2px;
}
