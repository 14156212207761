@import "../../styles/theme";

.input {
  background-color: transparent;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=number]:last-child {
    margin-right: 0 !important;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 0;
  }
}

.wrapper {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.close {
  outline: none;
  cursor: pointer;
  padding-left: 13px;
}

.dark {
  color: inherit;
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 110px;
  top: 9px;
}
