/* stylelint-disable block-no-empty */
/* stylelint-disable no-descending-specificity */
@import '../../styles/KrTheme.scss';

$sizeIcon: 11px;
$paddingLeft: 24px;
$paddingRight: 52px;
$paddingLeftSmall: 16px;
$paddingRightSmall: 24px;
$paddingRightIsExistIconEye: 55px;
$lineHeight: 24px;
$fontSize: 16px;
$input-height: 40px;
$input-height-small: 32px;
$marginCaption: 4px;
$border-radius-2: 2px;
$border-radius-24: 24px;
$disabled: #eee;
$error: #db4444;

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

/* wrapper styles */
.wrapper {
  position: relative;
  margin-bottom: calc($input-height / 2);

  &.fullWidth {
    width: 100%;
  }

  &.fitContent {
    width: fit-content;
  }
}

.inputWrap {
  position: relative;
}

/* general styles for input */
input.input {
  color: $black;
  font-size: $fontSize;
  line-height: $lineHeight;
  box-sizing: border-box;
  outline: none;
  font-family: inherit !important;
  height: $input-height;
  padding: 0 $paddingLeft 0 $paddingLeft;
  background-color: $blue-light;
  width: 100%;
  border: 1px solid transparent;
  border-radius: $border-radius-24;

  &:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }

  &:focus {
    background-color: $white;
    border: 1px solid $blue-hover;
  }

  &.errorInput {
    border-color: $error;
    background: $blue-light;

    &:focus {
      background-color: $white;

      & ~ .warn {
        display: none;
      }
    }
  }

  &.disabled {
    color: $dark-grey;
    background-color: $disabled;
    cursor: no-drop;
    border: 1px solid $disabled;
    pointer-events: none;

    + button.icon {
      pointer-events: none;
      cursor: default;
    }
  }

  &.placeholder,
  &:-ms-input-placeholder {
    color: $light-grey;
  }

  /* remove clear button for IE */
  &::-ms-clear {
    display: none;
  }

  &::-ms-reveal {
    display: none;
  }

  /* read only input style */
  &.readOnly {
    background: transparent;
    color: $black-text;
    padding-left: 0 !important;
    cursor: default;
    pointer-events: none;

    &:focus {
      border-color: transparent;
    }

    + button.icon {
      display: none;
    }
  }
}

input.closePadding {
  padding-right: $paddingRight;
}

.inputWrapIcon {
  position: absolute;
  align-items: center;
  top: 50%;
  left: 28px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  background-repeat: no-repeat;

  & + .input {
    padding-left: 54px;
  }

  &.search {
    background-image: url('../../images/icons/search.svg');
  }
}

input[type='number'] {
  -moz-appearance: textfield; /* For FireFox */

  &::-webkit-inner-spin-button { /* For Webkits like Chrome and Safari */
    -webkit-appearance: none;
    margin: 0;
  }
}

/* label styles */
.labelWrapper {
  position: relative;

  .label {
    @include setFont($size: 14px, $lineHeight: 20px, $weight: normal, $color: $dark-grey);

    margin-bottom: 4px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
    display: inline-block;

    &.focused {
      //top: -($topPositionLabel + 16px);
    }

    &.disabled {
      color: $light-grey;
    }

    &.hideLabel {
      display: none;
    }
  }

  .fieldDescription {
    position: absolute;
    right: 0;
    bottom: 8px;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
    z-index: 1;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

/* messages and errors */
.message {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 4px;

  &.error {
    @include setFont($size: 14px, $lineHeight: 20px);

    color: $error;

    &.focusedError {
      margin-top: $marginCaption - 2px;
    }
  }
}

.numberSymbols {
  @include setFont();

  position: absolute;
  right: 0;
  bottom: 0;

  &.numberSymbolsNotError {
    bottom: -$lineHeight;
  }
}

/* cross and eye */
button.icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  pointer-events: all;
  transform: translateY(-50%);

  > svg {
    pointer-events: none;
  }
}

/* small size inputs */
.small {
  .label {
    @include setFont($size: 12px, $lineHeight: 16px, $weight: normal, $color: $dark-grey);

    margin-bottom: 0;
  }

  input.input {
    height: $input-height-small;
    padding: 0 $paddingRightSmall 0 $paddingLeftSmall;
  }

  button.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    width: 12px;
    height: 12px;
  }

  .message {
    @include setFont($size: 12px, $lineHeight: 16px);

    margin-top: 0;
    font-size: 12px;
    line-height: 14px;

    &.error {
      @include setFont($size: 12px, $lineHeight: 16px);

      color: $error;

      &.focusedError {
        margin-top: $marginCaption - 2px;
      }
    }
  }

  .warn {
    right: 16px;
  }
}

.smallSquare {
  margin-bottom: 0;

  .label {
    @include setFont($size: 14px, $lineHeight: 20px, $weight: normal, $color: $dark-grey);

    margin-bottom: 0;
  }

  .input {
    height: $input-height-small;
    padding: 0 $paddingRightSmall 0 8px;
    border-radius: $border-radius-2;
  }

  button.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    width: 12px;
    height: 12px;
  }

  .message {
    margin-top: 0;
    font-size: 12px;
    line-height: 14px;
  }

  .warn {
    right: 8px;
  }
}

.textarea {
  @include setFont();

  color: $black;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit !important;
  min-height: $input-height;
  vertical-align: bottom;
  resize: none;
  outline: none;
  overflow: hidden;
  padding: 8px 56px 8px $paddingLeft;
  background-color: $blue-light;
  border: 1px solid transparent;
  border-radius: $border-radius-20;

  &:focus {
    background-color: $white;
    border: 1px solid $blue-hover;

    &.errorInput {
      border-color: $error;
      font-weight: bold;
    }
  }

  &.disabled {
    color: $dark-grey;
    background-color: $disabled;
  }
}

.text_area_div {
  @include setFont();

  width: 100%;
  font-family: inherit !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

// Удалить стили автокомплита
// Например, была проблема с неотображением иконок (иконка очистки / глаз)
input.input:first-of-type:-webkit-autofill,
input.input:first-of-type:-webkit-autofill:hover,
input.input:first-of-type:-webkit-autofill:focus,
textarea.textarea:first-of-type:-webkit-autofill,
textarea.textarea:first-of-type:-webkit-autofill:hover,
textarea.textarea:first-of-type:-webkit-autofill:focus {
  z-index: 0 !important;
  -webkit-box-shadow: 0 0 0 1000px $blue-light inset;
}

input.input:first-of-type:-webkit-autofill:focus,
textarea.textarea:first-of-type:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white inset;
}

/*
.labelRequired {
  padding-left: 5px;
  color: $error;
}
*/

//

.warn {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;

  &Icon {
    width: 12px;
    height: 12px;
    background: url(../../images/icons/warn.svg) 0 0 no-repeat;
    background-size: cover;
  }
}

.sameStyles {
  & input:disabled {
    padding: 8px 24px;
  }

  & textarea:disabled {
    padding: 8px 24px;
  }

  [class*=KrInput_label] {
    display: flex;
    font-size: 16px;
  }

  [class*=KrTooltip_container] svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: $blue-normal;
    }
  }
}

.sameStyles.wrapper {
  margin-bottom: 0;
}

.sameStyles [class*='message'] {
  position: absolute;
}
