.uidm-react-lib-iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.uidm-react-lib-iframe {
  width: 592px;
  border: none;
  position: relative;
  z-index: 20;
}
