@import "../../styles/theme";

$file-name-height: 24px;

.documentFile {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  align-items: flex-start;
}

.fileInfo {
  display: flex;
  flex-direction: column;
}

.fileSpecifications {
  margin-top: 4px;
}

.fileSpecificationsText {
  font-size: 12px;
  line-height: 16px;
  color: $dark-grey;
  text-transform: capitalize;
}

.nameFile {
  font-size: 16px;
  line-height: $file-name-height;
  max-width: 360px;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
  color: $blue-normal;
  outline: none;
}

.fileNameEffects {
  &:active {
    color: $blue-active;
  }

  &:hover {
    color: $blue-hover;
  }
}

.fileWrapper {
  display: inline-flex;
  min-width: 320px;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
}

.cursorPointer {
  cursor: pointer;
}

.printWrapper {
  max-width: 120px;
}

.button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  &:active {
    opacity: 0.7;
  }
}

.fileIconWrapper {
  width: 20px;
  height: 20px;
  margin-right: 19px;
}

.downloadButton {
  margin: 5px 12px 0 0;
}

.printButton {
  margin-left: auto;
  padding-left: 35px;
}

.preview {
  margin-left: 20px;
  outline: none;
}

.disabled {
  color: $dark-grey;
}
