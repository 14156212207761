@import '../../styles/KrTheme.scss';

.KrContractManagement {
  display: flex;
  align-items: center;
  width: 100%;
  height: 124px;

  & .exporterName {
    font-size: 16px;
    line-height: 24px;
    color: $blue-normal;
    margin-bottom: 4px;
  }

  & .serviceNames {
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey;
  }

  .marginRight24 {
    margin-right: 24px;
  }
}
