@import '../../styles/theme.scss';

.undo {
  position: absolute;
  max-width: 376px;
  padding: 12px 38px 12px 12px;
  font-size: 14px;
  line-height: 20px;
  color: $white;
  background-color: $dark-blue;
  border-radius: 6px;

  & .closeIcon {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 24px;
    height: 24px;
    border: none;

    &:focus {
      outline: none;
    }
  }

  a {
    color: $blue_03;
    border-bottom: 1px solid $blue_03;
  }
}
