@import '../../styles/theme';

.container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 72px;
  border-radius: 32px;
}

.toggleButton {
  box-sizing: border-box;
  position: absolute;
  right: 28px;
  display: flex;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  transition: top 0.3s ease;

  & svg {
    pointer-events: none;

    & path {
      pointer-events: none;
    }
  }
}

.badgeWrapper {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  border-radius: 32px;
  border: 1px solid $light;
  overflow: hidden;
  z-index: unset;
  outline: none;
  transition: max-height 0.3s ease;

  &.white {
    background-color: $white;
  }

  &.gray {
    background-color: $blue-light;
  }

  &.blue {
    background-color: $dark-blue;
  }

  &.active {
    z-index: 2;
  }
}

.badgeContent {
  box-sizing: border-box;
  display: flex;
  padding: 12px 66px 0 24px;

  & span {
    white-space: pre-wrap;
  }
}

.badgeHeader {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 20px 66px 20px 20px;

  & span {
    overflow: hidden;
    height: 100%;
    width: 60%;
    margin: 0 0 0 16px;
  }
}

.badgeFooter {
  box-sizing: content-box;
  height: 56px;
  padding: 23px 66px 23px 24px;
}
