@import '../../../../styles/KrTheme.scss';

.label {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: $dark-grey;
  margin-bottom: 4px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
  display: inline-block;

  &.hideLabel {
    display: none;
  }
}

.required {
  color: $error;
  padding-left: 3px;
}
