@import '../../styles/KrTheme.scss';

.KrNotificationPanel {
  position: absolute;
  right: 0;
  top: 0;
  width: 480px;
  height: 100%;
  padding: 112px 32px 32px 32px;
  background-color: $blue_05;
  margin-right: -480px;
  transition: 0.3s;
  z-index: 20;

  &.visible {
    margin-right: 0;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  & .title {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  & .settingsButton {
    width: 16px;
    height: 16px;
    background-image: url('../../images/icons/gearweel.svg');
  }
}
