@import "../../styles/theme";

.form {
  width: 100%;
  box-sizing: border-box;

  @extend %font;
}

.addButtonWrapper {
  display: flex;
  align-items: center;

  button {
    padding: 0;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent !important;
  }
}

.expansionWrapper > div {
  max-width: 936px;
  margin-bottom: 32px;
}

.expansionPanelDescription {
  text-align: right;
  color: $primary-text;
  font-weight: 400;
  font-size: 14px;
}

.container {
  background-color: $white;
  font-size: 16px;

  .submitButton {
    button {
      margin-top: 56px;
      width: 176px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 4px;
    }
  }

  .buttonWrapper {
    button {
      width: 100%;
      min-width: 176px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 4px;
    }
  }
}

.withPaddings {
  padding: 60px 48px 32px;
}

.col {
  margin-bottom: 64px;
}

.groupCol {
  margin-bottom: 40px;
}

.textCol {
  margin-top: 19px;
}

.title {
  margin-bottom: 60px;
}

.error {
  color: $error;
  font-size: 10px;
  line-height: 12px;
  padding-top: 10px;
}

.addIcon {
  margin-right: 10px;
  height: 24px;
}

.loaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .loader div::after {
    background: $dark-text;
  }
}

.textMargin {
  margin-bottom: 20px;
}

.labelText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: $dark-grey;
}

.groupLabel {
  color: $black;
}

.groupError {
  margin-top: -35px;
  padding-bottom: 40px;
  line-height: 12px;
  font-size: 16px;
}

.separator {
  border-top: 2px solid $blue-disabled;
  margin-top: 10px;
  margin-bottom: 30px;
  opacity: 0.3;
}

.html {
  margin: 20px 0;
}

.innerHtml {
  width: 100%;
  margin-bottom: 7px;
}

.bigLabel {
  margin-bottom: 25px;
  margin-left: 10px;
}

.labelRequired {
  padding-left: 5px;
  color: $error;
}

.groupMargin {
  margin-left: 21px;
}

.hiddenLabel {
  display: inline-flex;
  overflow: hidden;
  max-height: 24px;
}

.customMessage {
  padding: 15px 0;
}
