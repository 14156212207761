@import "../../styles/theme";

.rectangular,
.round {
  width: 100%;
}

.rectangular {
  border: 1px solid $light;
}

.rectangular:hover {
  background: $white;
  border: 1px solid $blue-hover;
}

.round {
  background: $disabled;
  border-radius: $border-radius-32;
  border: 1px solid transparent;
}

.round:hover {
  background: $white;
  border: 1px solid $blue-normal;
}
