@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-radius: $borderRadiusBig;
  background-color: $white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 72px;
  padding: 24px 24px 24px 30px;
  box-sizing: border-box;
  border: 1px solid $blue-light;
  background-color: $blue-light;
  outline: none;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  border-top-left-radius: $borderRadiusBig;
  border-top-right-radius: $borderRadiusBig;

  & .title {
    text-overflow: ellipsis;
    max-height: 56px;
    flex-flow: wrap;
    overflow: hidden;
  }

  .rightText,
  .status {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: $dark-grey;
    margin-left: 32px;
  }

  .status {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;

    > div {
      margin-left: auto;
    }
  }

  & .chevron {
    transition: transform 0.2s ease-in-out;
    margin-right: 22px;
  }

  & .chevronWrap {
    display: flex;
    align-items: center;
    padding-left: 32px;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      width: 12px;
      height: 6px;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEyIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuODA0NyA1LjgzMTI1QzExLjU0NDQgNi4wNTYyNSAxMS4xMjIzIDYuMDU2MjUgMTAuODYxOSA1LjgzMTI1TDYgMS42Mjk1NkwxLjEzODA3IDUuODMxMjVDMC44Nzc3MjIgNi4wNTYyNSAwLjQ1NTYxMSA2LjA1NjI1IDAuMTk1MjYyIDUuODMxMjVDLTAuMDY1MDg3MiA1LjYwNjI2IC0wLjA2NTA4NzEgNS4yNDE0NyAwLjE5NTI2MiA1LjAxNjQ3TDYgLTEuNTczNjFlLTA2TDExLjgwNDcgNS4wMTY0N0MxMi4wNjUxIDUuMjQxNDcgMTIuMDY1MSA1LjYwNjI2IDExLjgwNDcgNS44MzEyNVoiIGZpbGw9IiM3QTdBN0MiLz4KPC9zdmc+Cg==') no-repeat 50% 50%;
      background-size: 12px 6px;
      transform: rotate(180deg);
      transition: 0.2s ease transform;
    }
  }

  &.success {
    .status {
      color: $green-01;
    }
  }

  &.error {
    .status {
      color: $error;
    }
  }

  &.opened {
    border-color: $blue-disabled;

    .chevronWrap::before {
      transform: rotate(0);
      transition: 0.2s ease transform;
    }
  }

  &.disabled {
    background-color: $disabled;
    border-color: $disabled;
    pointer-events: none;
  }
}

.deleteButton {
  margin-bottom: 24px;
}

.expansionContent {
  transition: height 0.4s ease-out;
  height: auto;
}

.collapsed {
  overflow: hidden;
}

.boxShadow {
  box-shadow: $box-shadow;
}

.content {
  box-shadow: inset -1px 0 0 $blue-disabled, inset 1px 0 0 $blue-disabled, inset 0 -1px 0 $blue-disabled;
  padding: 32px 24px;
  border-bottom-left-radius: $borderRadiusBig;
  border-bottom-right-radius: $borderRadiusBig;
}

.footer {
  height: 76px;
  border-top-width: 1px;
  border-color: $blue-disabled;
}
