@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  align-items: stretch;
}

.button {
  padding: 9px 40px;
  background-color: $blue-light;
  color: $blue-normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &:first-child {
    border-radius: 100px 0 0 100px;
  }

  &:last-child {
    border-radius: 0 100px 100px 0;
  }

  &:hover {
    background-color: rgba($blue-normal, 0.15);
  }
}

.activeButton {
  color: #fff;
  background-color: $blue-normal;
  cursor: default;
  pointer-events: none;

  &:hover {
    background-color: $blue-normal;
  }
}

.disabled {
  pointer-events: none;
  color: $light-grey;
}
