@import '../../styles/KrTheme.scss';

$paddingLeft: 24px;
$marginCaption: 7px;

.dropdownWrapper {
  position: relative;
  width: 100%;
  font-family: 'Roboto', sans-serif; // inherit !important;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.hide {
    display: none;
  }

  &.rightSide {
    justify-content: flex-end;
  }
}

.input {
  @include setFont();

  width: 100%;
  outline: none;
  border: none;
  height: 24px;
  flex-grow: 1;
  padding: 18px 23px;
  color: $black;
  background-color: transparent;
  border-radius: $border-radius-28;
  min-width: 0;

  /* remove clear button for IE */
  &::-ms-clear {
    display: none;
  }

  &::-ms-reveal {
    display: none;
  }
}

.inputPointer {
  cursor: pointer;
}

.input:disabled {
  background-color: $disabled;
}

.label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: $dark-grey;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;

  &.hideLabel {
    display: none;
  }
}

.required {
  line-height: 16px;
  color: $error;
  padding-left: 3px;
}

.icon {
  width: fit-content;
  height: fit-content;
  padding: 12px 20px 12px 16px;
  border: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  pointer-events: all;
}

.input:disabled ~ .label {
  color: $light-grey;
}

input.input:disabled ~ button.icon {
  pointer-events: none;
  cursor: default;
}

.indicator {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 1px;
  width: 100%;

  &.active {
    bottom: -1px;
    height: 2px;

    &.error {
      background-color: $error;
      bottom: 20px;
      left: 0;
    }
  }
}

.message {
  display: block;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  height: 16px;
  margin-top: 4px;

  &.error {
    color: $error;
    font-weight: normal;
  }
}

.wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: $blue-light;
  border: 1px solid $blue-light;
  border-radius: $border-radius-28;
  height: 40px;

  &.active {
    background-color: $blue-light;
    border-color: $blue-normal;
    z-index: 6;
  }

  &.fitContent {
    width: fit-content;
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabledWrapper {
    background-color: $disabled;
    cursor: no-drop;

    &:hover {
      border-color: $disabled;
    }

    .inputPointer {
      cursor: no-drop;
    }
  }
}

.errorStyle {
  border-color: $error !important;
}

.selects {
  list-style-type: none;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: auto;
  background-color: $white;
  opacity: 1;
  outline: none;
  position: absolute;
  left: 0;
  top: 44px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: $drop-shadow;
  border-radius: 2px;
  z-index: 5;

  &.withLabel {
    top: 68px;
  }

  &.inactive {
    display: none;
  }

  &.active {
    display: block;
  }
}

.items {
  width: 100%;
  min-height: 48px;
  box-sizing: border-box;
  padding: 8px 16px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  cursor: pointer;

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  &.active {
    background-color: $blue-light;
    border-color: $blue-normal;

    & * {
      color: $blue-normal;
    }
  }

  &.inactive {
    background-color: transparent;
  }

  &:hover {
    background-color: $blue-light;
  }

  a {
    color: $blue-normal;
    text-decoration: underline;
  }
}

.itemsAdditional {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.blueLoader {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.blueLoader div::after {
  background: $blue-normal;
}

.tooltip {
  color: $dark-grey;

  svg {
    margin-bottom: 2px;
  }
}

.tooltipLabel {
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
}

button.icon > svg {
  pointer-events: none;
}

.sameStyles {
  [class*=dropdownHeader] > span {
    font-size: 16px;
  }

  [class*=KrTooltip_container] svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: $blue-normal;
    }
  }
}

.errorBorderStyles {
  border-color: $error !important;
}
