@import "../../../../styles/theme";

$inputHeight: 56px;
$halfInputHeight: calc($inputHeight / 2);

.listContainer {
  box-sizing: border-box;
  z-index: 1;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: $halfInputHeight;
  width: 100%;
  max-height: 192px;
  list-style: none;
  padding-top: calc(#{$halfInputHeight} + 28px);
  margin: 0;
  box-shadow: 0 0 24px rgba(213, 220, 235, 0.3);
  background-color: $white;
  border: 1px solid $blue-normal;

  & .listItem {
    width: 100%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 12px 24px;
    cursor: pointer;
    align-items: center;

    &:last-child {
      padding-bottom: 28px;
    }

    &.selected {
      background-color: $light;
    }

    &:hover {
      background-color: $light;
    }
  }

  & span {
    font-family: inherit;
    pointer-events: none;
  }

  & span.bold {
    font-weight: bold;
    color: $blue-active;
    pointer-events: none;
  }
}

p.tag {
  align-self: flex-start;
  pointer-events: none;
  width: 20%;
  text-align: start;
  font-family: inherit;
  letter-spacing: 1px;
  color: $black;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.subTitle {
  pointer-events: none;
  padding: 0 5px;
  text-align: start;
  font-family: inherit;
  letter-spacing: 1px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.headerTitle {
  margin-bottom: 11px;
  margin-left: 24px;
}

p.title {
  pointer-events: none;
  flex: 1;
  padding: 0 5px;
  margin: 0;
  text-align: start;
  font-family: inherit;

  &::first-letter {
    text-transform: uppercase;
  }
}

.showAllButton {
  margin: 10px 16px 28px;
  font-size: 16px;
  line-height: 24px;
  color: $blue-normal;
  text-decoration: underline;
  cursor: pointer;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.inRow {
    flex-direction: row;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 320px;
}
