@import "../../../../styles/theme";

$BADGES_WRAPPER_ONE_LINE_HEIGHT: 63px;

.dropdown {
  padding-top: 19px;
}

.badgesWrapperBlock {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: baseline;
}

.badgesWrapper {
  display: inline-flex; // For IE
  margin: 2px 20px 19px 3px;
}

//.linkTypography {
//  @include setFont();
//
//  text-align: center;
//  text-decoration: underline;
//  text-transform: initial;
//}

.showAllButtonWrapper {
  display: flex;
  justify-content: flex-start;
}

.hiddenLabel {
  display: inline-flex;
  overflow: hidden;
  max-height: 24px;
}
