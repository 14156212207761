@import '../../styles/KrTheme.scss';

.KrDocsBlockWrapper {
  background: white;
  padding-right: 20px;
  padding-bottom: 25px;
}

.KrInfoTextWrapper {
  margin: 20px 5px;
}

.KrDocsWrapper {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 228px;
    padding: 8px 0;
  }
}
