@import '../../../../styles/KrTheme.scss';

.container {
  display: flex;
  align-items: center;
}

.sorter {
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  color: $dark-grey;

  &.asc {
    transform: rotate(180deg) scale(-1, 1);
  }

  &.sorted {
    color: $blue-normal;
  }
}

.columnName {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: $dark-grey;
}

.tooltipWrapper {
  width: 16px;
  height: 16px;
  margin: -2px 0 0 8px;
  color: $blue-normal;
}
