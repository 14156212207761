@import '../../styles/KrTheme.scss';

.KrListStylized {
  list-style: none;

  & .listItem {
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: 8px;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  &.insideNotification {
    & .listItem {
      font-size: 14px;
      line-height: 20px;
    }

    & .listItem::before {
      top: 6px;
      left: -3px;
    }
  }

  &.info {
    & .listItem {
      &::before {
        background-color: $blue-normal;
      }
    }
  }

  &.success {
    & .listItem {
      &::before {
        background-color: $green_01;
      }
    }
  }

  &.error {
    & .listItem {
      &::before {
        background-color: $error;
      }
    }
  }

  &.warning {
    & .listItem {
      &::before {
        background-color: $warning;
      }
    }
  }

  &.gray {
    & .listItem {
      color: $dark-grey;

      &::before {
        background-color: $light-grey;
      }
    }
  }

  &.default {
    & .listItem {
      &::before {
        background-color: $light-grey;
      }
    }
  }
}
