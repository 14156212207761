/* stylelint-disable */

// new theme colors
$black: #000;
$dark-grey: #7a7a7c;
$light-grey: #9c9d9e;
$star-grey: #e4e5e6;
$light: #eee;
$disabled: #f9f9f9;
$white: #fff;
$orange: #ff8515;
$transparent: transparent;

$blue-active: #233de0;
$blue-normal: #6177ff;
$blue-hover: #788bfb;
$blue-disabled: #d5dceb;
$blue-light: #f2f5fe;
$blue-lighter: #f8faff;
$dark-blue: #2c386b;
$blue-crumbs: #4151a2;

$error: #db4444;
$errorActive: #bb2929;
$errorHover: #f86464;
$errorLight: #eda2a2;

//new design box-shadow
$box-shadow: 0 0 24px rgba(213, 220, 235, 0.3);

// new border-radius
$border-radius-100: 100px;
$border-radius-32: 32px;
$border-radius-24: 24px;
$border-radius-28: 28px;
$border-radius-20: 20px;
$border-radius-8: 8px;
$border-radius-0: 0;

//new z-index
$tooltip-z-index: 30;
$dropdown-z-index: 20;

$transparentOpacity: 0.12;

@mixin setFont($size: 16px, $lineHeight: 24px, $weight: normal, $color: $dark-grey) {

  font: {

    size: $size;
    weight: $weight;
  }

  line-height: $lineHeight;
  color: $color;
}

//start old design

// theme colors
$brand-color: #56bdff;
$muted-color: #e3e1f3;
$background-color: #f7f7fb;
$badge-background: #b63333;
$dark: #15122f;
$primary: #656289;
$blue: #3f75ff;
$black-text: #000;

// text colors
$muted-text-color: #a2a1bb;
$dark-text: #15122f;
$primary-text: #656289;
$error-text: #eb5757;

// main theme
$main: #0a2750;
$secondary: #3579b8;
$third: #a2a1bb;
$fourth: #dff0ff;
$background: #f7f7fb;
$accent: #0047ff;
$green: #30ca89;
$expansion-panel-box-shadow-color: rgba(32, 28, 77, 0.16);
$kr-expansion-panel-box-shadow-color: rgba(32, 28, 77, 0.16);

//border radius
$borderRadiusBig: 32px;
$borderRadiusSmall: 8px;

// end start old design

// fonts
%font {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
}

// mixins


/* @mixin setFont($size: 12px, $lineHeight: 16px, $weight: normal, $color: $primary-text) {
  font: {
    size: $size;
    weight: $weight;
  }

  line-height: $lineHeight;
  color: $color;
} Old design */

@mixin borderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin pseudo() {
  position: absolute;
  content: '';
}

// breakpoints
$breakpoint-extra-large: 1200px;
$breakpoint-large: 992px;
$breakpoint-medium: 768px;
$breakpoint-small: 576px;

// Form
$form-max-width: 904px;

// sizes
$input-height: 42px;

// Korus
$lightgreen-background: #e4f8ee;
$green_01: #349c6a;
$tooltipBackground: #ffffff;
$danger: #ffe2e2;
$success: #e4f8ee;
$success-border: #349c6a;
$warning: #f2831c;
$red_02: #ffe2e2;
$red_03: #e26969;
$orange-background: #ffeddc;
$blue_0: #344883;
$blue_0_hover: #43599b;
$blue_03: #99a7f9;
$blue_05: #f2f5fe;
$circle-button-hover: $blue_03;
$drop-shadow: 0 3px 30px rgba(46, 61, 121, 0.28);

/* roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('../fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'), url('../fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url('../fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('../fonts/Roboto-BlackItalic.ttf') format('truetype');
}
