@import "../../../../styles/theme";

.wrapper {
  width: 100%;
  max-width: 936px;
  font-family: 'Roboto', sans-serif;
  padding: 28px 48px 32px;
  box-sizing: border-box;

  @media (max-width: $breakpoint-medium) {
    padding: 21px 16px 32px;
  }
}

.description {
  margin-bottom: 68px;
  margin-left: 23px;

  @media (max-width: $breakpoint-medium) {
    margin-left: 0;
  }
}

.descriptionWidth {
  max-width: 307px;
  width: 100%;
  min-width: 100px;
}

.footer {
  display: flex;
  margin-top: 35px;
}
