@import '../../styles/KrTheme.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  color: $blue-crumbs;
  font-size: 16px;
  line-height: 20px;
  cursor: default;
}

.crumb {
  display: inline-block;
  position: relative;
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  visibility: visible;
  padding-right: 16px;

  &::after {
    position: absolute;
    content: '/';
    right: 4px;
  }

  &.clickable {
    cursor: pointer;
    pointer-events: auto;
  }

  &.clickable.last,
  &.last {
    opacity: 0.7;
    cursor: default;
    pointer-events: none;

    &::after {
      content: none;
    }
  }
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  visibility: visible;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
