@import '../../../styles/KrTheme';

.title {
  display: block;
  font-size: 16px;
  line-height: 24px;
}

.text,
.subtitle {
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.subtitle {
  display: block;
  color: $dark-grey;
}
