@import "../../../../styles/theme";

.navWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-medium) {
    margin-left: 0;
    justify-content: space-between;
  }
}

.nextButton {
  margin-left: 53px;

  @media (max-width: $breakpoint-medium) {
    margin-left: 0;
  }
}

.submitButton {
  button {
    min-width: 248px;
  }
}

.navButton {
  button {
    padding: 0;
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent !important;
  }
}
