.uidm-react-lib-lds-spinner {
  color: inherit;
  display: inline-block;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uidm-react-lib-lds-spinner div {
  transform-origin: 19px 22px;
  animation: uidm-react-lib-lds-spinner 1.2s linear infinite;
}

.uidm-react-lib-lds-spinner div::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 18px;
  width: 2px;
  height: 11px;
  border-radius: 20%;
  background: #ffffff;
}

.uidm-react-lib-lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.05s;
}

.uidm-react-lib-lds-spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.9s;
}

.uidm-react-lib-lds-spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.75s;
}

.uidm-react-lib-lds-spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.6s;
}

.uidm-react-lib-lds-spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.45s;
}

.uidm-react-lib-lds-spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.3s;
}

.uidm-react-lib-lds-spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.15s;
}

.uidm-react-lib-lds-spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
}

@keyframes uidm-react-lib-lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
