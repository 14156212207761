@import "../../styles/theme";

.tab-wrapper {
  @extend %font;

  width: 100%;
  min-width: 46px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  outline: none;
  margin: 4px;
  cursor: pointer;
}

.tab {
  text-transform: uppercase;
  color: $primary;

  &-color-active {
    color: $dark;
  }

  &-color-disabled {
    color: $muted-text-color;
  }
}

.underline {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -14px;
  left: 0;
  color: $dark;

  &-color-dark {
    color: $dark;
    border-color: $dark;
    background-color: $dark;
  }

  &-color-blue {
    color: $blue;
    border-color: $blue;
    background-color: $blue;
  }

  &-color-primary {
    color: $primary;
    border-color: $primary;
    background-color: $primary;
  }
}
