/* stylelint-disable no-descending-specificity */
@import '../../styles/KrTheme.scss';

.container {
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.noGutters {
  margin-right: 0;
  margin-left: 0;
}

.noGutters > .col,
.noGutters > [class*="col"] {
  padding-right: 0;
  padding-left: 0;
}

.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12,
.col,
.colAuto,
.colSm1,
.colSm2,
.colSm3,
.colSm4,
.colSm5,
.colSm6,
.colSm7,
.colSm8,
.colSm9,
.colSm10,
.colSm11,
.colSm12,
.colSm,
.colSmAuto,
.colMd1,
.colMd2,
.colMd3,
.colMd4,
.colMd5,
.colMd6,
.colMd7,
.colMd8,
.colMd9,
.colMd10,
.colMd11,
.colMd12,
.colMd,
.colMdAuto,
.colLg1,
.colLg2,
.colLg3,
.colLg4,
.colLg5,
.colLg6,
.colLg7,
.colLg8,
.colLg9,
.colLg10,
.colLg11,
.colLg12,
.colLg,
.colLgAuto,
.colXl1,
.colXl2,
.colXl3,
.colXl4,
.colXl5,
.colXl6,
.colXl7,
.colXl8,
.colXl9,
.colXl10,
.colXl11,
.colXl12,
.colXl,
.colXlAuto,
.colXxl1,
.colXxl2,
.colXxl3,
.colXxl4,
.colXxl5,
.colXxl6,
.colXxl7,
.colXxl8,
.colXxl9,
.colXxl10,
.colXxl11,
.colXxl12,
.colXxl,
.colXxlAuto,
.colLd1,
.colLd2,
.colLd3,
.colLd4,
.colLd5,
.colLd6,
.colLd7,
.colLd8,
.colLd9,
.colLd10,
.colLd11,
.colLd12,
.colLd,
.colLdAuto {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.rowCols1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.rowCols2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.rowCols3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.rowCols4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.rowCols5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.rowCols6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.colAuto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.orderFirst {
  order: -1;
}

.orderLast {
  order: 13;
}

.order0 {
  order: 0;
}

.order1 {
  order: 1;
}

.order2 {
  order: 2;
}

.order3 {
  order: 3;
}

.order4 {
  order: 4;
}

.order5 {
  order: 5;
}

.order6 {
  order: 6;
}

.order7 {
  order: 7;
}

.order8 {
  order: 8;
}

.order9 {
  order: 9;
}

.order10 {
  order: 10;
}

.order11 {
  order: 11;
}

.order12 {
  order: 12;
}

.offset1 {
  margin-left: 8.33333%;
}

.offset2 {
  margin-left: 16.66667%;
}

.offset3 {
  margin-left: 25%;
}

.offset4 {
  margin-left: 33.33333%;
}

.offset5 {
  margin-left: 41.66667%;
}

.offset6 {
  margin-left: 50%;
}

.offset7 {
  margin-left: 58.33333%;
}

.offset8 {
  margin-left: 66.66667%;
}

.offset9 {
  margin-left: 75%;
}

.offset10 {
  margin-left: 83.33333%;
}

.offset11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .colSm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .rowColsSm1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowColsSm2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .rowColsSm3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .rowColsSm4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .rowColsSm5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .rowColsSm6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colSmAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .colSm1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .colSm2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colSm3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colSm4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .colSm5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .colSm6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colSm7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .colSm8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .colSm9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colSm10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .colSm11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .colSm12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .orderSmFirst {
    order: -1;
  }

  .orderSmLast {
    order: 13;
  }

  .orderSm0 {
    order: 0;
  }

  .orderSm1 {
    order: 1;
  }

  .orderSm2 {
    order: 2;
  }

  .orderSm3 {
    order: 3;
  }

  .orderSm4 {
    order: 4;
  }

  .orderSm5 {
    order: 5;
  }

  .orderSm6 {
    order: 6;
  }

  .orderSm7 {
    order: 7;
  }

  .orderSm8 {
    order: 8;
  }

  .orderSm9 {
    order: 9;
  }

  .orderSm10 {
    order: 10;
  }

  .orderSm11 {
    order: 11;
  }

  .orderSm12 {
    order: 12;
  }

  .offsetSm0 {
    margin-left: 0;
  }

  .offsetSm1 {
    margin-left: 8.33333%;
  }

  .offsetSm2 {
    margin-left: 16.66667%;
  }

  .offsetSm3 {
    margin-left: 25%;
  }

  .offsetSm4 {
    margin-left: 33.33333%;
  }

  .offsetSm5 {
    margin-left: 41.66667%;
  }

  .offsetSm6 {
    margin-left: 50%;
  }

  .offsetSm7 {
    margin-left: 58.33333%;
  }

  .offsetSm8 {
    margin-left: 66.66667%;
  }

  .offsetSm9 {
    margin-left: 75%;
  }

  .offsetSm10 {
    margin-left: 83.33333%;
  }

  .offsetSm11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .colMd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .rowColsMd1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowColsMd2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .rowColsMd3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .rowColsMd4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .rowColsMd5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .rowColsMd6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colMdAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .colMd1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .colMd2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colMd3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colMd4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .colMd5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .colMd6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colMd7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .colMd8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .colMd9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colMd10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .colMd11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .colMd12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .orderMdFirst {
    order: -1;
  }

  .orderMdLast {
    order: 13;
  }

  .orderMd0 {
    order: 0;
  }

  .orderMd1 {
    order: 1;
  }

  .orderMd2 {
    order: 2;
  }

  .orderMd3 {
    order: 3;
  }

  .orderMd4 {
    order: 4;
  }

  .orderMd5 {
    order: 5;
  }

  .orderMd6 {
    order: 6;
  }

  .orderMd7 {
    order: 7;
  }

  .orderMd8 {
    order: 8;
  }

  .orderMd9 {
    order: 9;
  }

  .orderMd10 {
    order: 10;
  }

  .orderMd11 {
    order: 11;
  }

  .orderMd12 {
    order: 12;
  }

  .offsetMd0 {
    margin-left: 0;
  }

  .offsetMd1 {
    margin-left: 8.33333%;
  }

  .offsetMd2 {
    margin-left: 16.66667%;
  }

  .offsetMd3 {
    margin-left: 25%;
  }

  .offsetMd4 {
    margin-left: 33.33333%;
  }

  .offsetMd5 {
    margin-left: 41.66667%;
  }

  .offsetMd6 {
    margin-left: 50%;
  }

  .offsetMd7 {
    margin-left: 58.33333%;
  }

  .offsetMd8 {
    margin-left: 66.66667%;
  }

  .offsetMd9 {
    margin-left: 75%;
  }

  .offsetMd10 {
    margin-left: 83.33333%;
  }

  .offsetMd11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1024px) {
  .colLg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .rowColsLg1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowColsLg2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .rowColsLg3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .rowColsLg4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .rowColsLg5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .rowColsLg6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colLgAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .colLg1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .colLg2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colLg3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colLg4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .colLg5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .colLg6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colLg7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .colLg8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .colLg9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colLg10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .colLg11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .colLg12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .orderLgFirst {
    order: -1;
  }

  .orderLgLast {
    order: 13;
  }

  .orderLg0 {
    order: 0;
  }

  .orderLg1 {
    order: 1;
  }

  .orderLg2 {
    order: 2;
  }

  .orderLg3 {
    order: 3;
  }

  .orderLg4 {
    order: 4;
  }

  .orderLg5 {
    order: 5;
  }

  .orderLg6 {
    order: 6;
  }

  .orderLg7 {
    order: 7;
  }

  .orderLg8 {
    order: 8;
  }

  .orderLg9 {
    order: 9;
  }

  .orderLg10 {
    order: 10;
  }

  .orderLg11 {
    order: 11;
  }

  .orderLg12 {
    order: 12;
  }

  .offsetLg0 {
    margin-left: 0;
  }

  .offsetLg1 {
    margin-left: 8.33333%;
  }

  .offsetLg2 {
    margin-left: 16.66667%;
  }

  .offsetLg3 {
    margin-left: 25%;
  }

  .offsetLg4 {
    margin-left: 33.33333%;
  }

  .offsetLg5 {
    margin-left: 41.66667%;
  }

  .offsetLg6 {
    margin-left: 50%;
  }

  .offsetLg7 {
    margin-left: 58.33333%;
  }

  .offsetLg8 {
    margin-left: 66.66667%;
  }

  .offsetLg9 {
    margin-left: 75%;
  }

  .offsetLg10 {
    margin-left: 83.33333%;
  }

  .offsetLg11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .colXl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .rowColsXl1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowColsXl2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .rowColsXl3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .rowColsXl4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .rowColsXl5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .rowColsXl6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colXlAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .colXl1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .colXl2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colXl3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colXl4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .colXl5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .colXl6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colXl7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .colXl8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .colXl9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colXl10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .colXl11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .colXl12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .orderXlFirst {
    order: -1;
  }

  .orderXlLast {
    order: 13;
  }

  .orderXl0 {
    order: 0;
  }

  .orderXl1 {
    order: 1;
  }

  .orderXl2 {
    order: 2;
  }

  .orderXl3 {
    order: 3;
  }

  .orderXl4 {
    order: 4;
  }

  .orderXl5 {
    order: 5;
  }

  .orderXl6 {
    order: 6;
  }

  .orderXl7 {
    order: 7;
  }

  .orderXl8 {
    order: 8;
  }

  .orderXl9 {
    order: 9;
  }

  .orderXl10 {
    order: 10;
  }

  .orderXl11 {
    order: 11;
  }

  .orderXl12 {
    order: 12;
  }

  .offsetXl0 {
    margin-left: 0;
  }

  .offsetXl1 {
    margin-left: 8.33333%;
  }

  .offsetXl2 {
    margin-left: 16.66667%;
  }

  .offsetXl3 {
    margin-left: 25%;
  }

  .offsetXl4 {
    margin-left: 33.33333%;
  }

  .offsetXl5 {
    margin-left: 41.66667%;
  }

  .offsetXl6 {
    margin-left: 50%;
  }

  .offsetXl7 {
    margin-left: 58.33333%;
  }

  .offsetXl8 {
    margin-left: 66.66667%;
  }

  .offsetXl9 {
    margin-left: 75%;
  }

  .offsetXl10 {
    margin-left: 83.33333%;
  }

  .offsetXl11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1400px) {
  .colXxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .rowColsXxl1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowColsXxl2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .rowColsXxl3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .rowColsXxl4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .rowColsXxl5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .rowColsXxl6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colXxlAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .colXxl1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .colXxl2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colXxl3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colXxl4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .colXxl5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .colXxl6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colXxl7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .colXxl8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .colXxl9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colXxl10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .colXxl11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .colXxl12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .orderXxlFirst {
    order: -1;
  }

  .orderXxlLast {
    order: 13;
  }

  .orderXxl0 {
    order: 0;
  }

  .orderXxl1 {
    order: 1;
  }

  .orderXxl2 {
    order: 2;
  }

  .orderXxl3 {
    order: 3;
  }

  .orderXxl4 {
    order: 4;
  }

  .orderXxl5 {
    order: 5;
  }

  .orderXxl6 {
    order: 6;
  }

  .orderXxl7 {
    order: 7;
  }

  .orderXxl8 {
    order: 8;
  }

  .orderXxl9 {
    order: 9;
  }

  .orderXxl10 {
    order: 10;
  }

  .orderXxl11 {
    order: 11;
  }

  .orderXxl12 {
    order: 12;
  }

  .offsetXxl0 {
    margin-left: 0;
  }

  .offsetXxl1 {
    margin-left: 8.33333%;
  }

  .offsetXxl2 {
    margin-left: 16.66667%;
  }

  .offsetXxl3 {
    margin-left: 25%;
  }

  .offsetXxl4 {
    margin-left: 33.33333%;
  }

  .offsetXxl5 {
    margin-left: 41.66667%;
  }

  .offsetXxl6 {
    margin-left: 50%;
  }

  .offsetXxl7 {
    margin-left: 58.33333%;
  }

  .offsetXxl8 {
    margin-left: 66.66667%;
  }

  .offsetXxl9 {
    margin-left: 75%;
  }

  .offsetXxl10 {
    margin-left: 83.33333%;
  }

  .offsetXxl11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1680px) {
  .colLd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .rowColsLd1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowColsLd2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .rowColsLd3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .rowColsLd4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .rowColsLd5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .rowColsLd6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colLdAuto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .colLd1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .colLd2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .colLd3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .colLd4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .colLd5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .colLd6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .colLd7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .colLd8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .colLd9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .colLd10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .colLd11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .colLd12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .orderLdFirst {
    order: -1;
  }

  .orderLdLast {
    order: 13;
  }

  .orderLd0 {
    order: 0;
  }

  .orderLd1 {
    order: 1;
  }

  .orderLd2 {
    order: 2;
  }

  .orderLd3 {
    order: 3;
  }

  .orderLd4 {
    order: 4;
  }

  .orderLd5 {
    order: 5;
  }

  .orderLd6 {
    order: 6;
  }

  .orderLd7 {
    order: 7;
  }

  .orderLd8 {
    order: 8;
  }

  .orderLd9 {
    order: 9;
  }

  .orderLd10 {
    order: 10;
  }

  .orderLd11 {
    order: 11;
  }

  .orderLd12 {
    order: 12;
  }

  .offsetLd0 {
    margin-left: 0;
  }

  .offsetLd1 {
    margin-left: 8.33333%;
  }

  .offsetLd2 {
    margin-left: 16.66667%;
  }

  .offsetLd3 {
    margin-left: 25%;
  }

  .offsetLd4 {
    margin-left: 33.33333%;
  }

  .offsetLd5 {
    margin-left: 41.66667%;
  }

  .offsetLd6 {
    margin-left: 50%;
  }

  .offsetLd7 {
    margin-left: 58.33333%;
  }

  .offsetLd8 {
    margin-left: 66.66667%;
  }

  .offsetLd9 {
    margin-left: 75%;
  }

  .offsetLd10 {
    margin-left: 83.33333%;
  }

  .offsetLd11 {
    margin-left: 91.66667%;
  }
}

.dNone {
  display: none !important;
}

.dInline {
  display: inline !important;
}

.dInlineBlock {
  display: inline-block !important;
}

.dBlock {
  display: block !important;
}

.dTable {
  display: table !important;
}

.dTableRow {
  display: table-row !important;
}

.dTableCell {
  display: table-cell !important;
}

.dFlex {
  display: flex !important;
}

.dInlineFlex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .dSmNone {
    display: none !important;
  }

  .dSmInline {
    display: inline !important;
  }

  .dSmInlineBlock {
    display: inline-block !important;
  }

  .dSmBlock {
    display: block !important;
  }

  .dSmTable {
    display: table !important;
  }

  .dSmTableRow {
    display: table-row !important;
  }

  .dSmTableCell {
    display: table-cell !important;
  }

  .dSmFlex {
    display: flex !important;
  }

  .dSmInlineFlex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .dMdNone {
    display: none !important;
  }

  .dMdInline {
    display: inline !important;
  }

  .dMdInlineBlock {
    display: inline-block !important;
  }

  .dMdBlock {
    display: block !important;
  }

  .dMdTable {
    display: table !important;
  }

  .dMdTableRow {
    display: table-row !important;
  }

  .dMdTableCell {
    display: table-cell !important;
  }

  .dMdFlex {
    display: flex !important;
  }

  .dMdInlineFlex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .dLgNone {
    display: none !important;
  }

  .dLgInline {
    display: inline !important;
  }

  .dLgInlineBlock {
    display: inline-block !important;
  }

  .dLgBlock {
    display: block !important;
  }

  .dLgTable {
    display: table !important;
  }

  .dLgTableRow {
    display: table-row !important;
  }

  .dLgTableCell {
    display: table-cell !important;
  }

  .dLgFlex {
    display: flex !important;
  }

  .dLgInlineFlex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .dXlNone {
    display: none !important;
  }

  .dXlInline {
    display: inline !important;
  }

  .dXlInlineBlock {
    display: inline-block !important;
  }

  .dXlBlock {
    display: block !important;
  }

  .dXlTable {
    display: table !important;
  }

  .dXlTableRow {
    display: table-row !important;
  }

  .dXlTableCell {
    display: table-cell !important;
  }

  .dXlFlex {
    display: flex !important;
  }

  .dXlInlineFlex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .dXxlNone {
    display: none !important;
  }

  .dXxlInline {
    display: inline !important;
  }

  .dXxlInlineBlock {
    display: inline-block !important;
  }

  .dXxlBlock {
    display: block !important;
  }

  .dXxlTable {
    display: table !important;
  }

  .dXxlTableRow {
    display: table-row !important;
  }

  .dXxlTableCell {
    display: table-cell !important;
  }

  .dXxlFlex {
    display: flex !important;
  }

  .dXxlInlineFlex {
    display: inline-flex !important;
  }
}

@media (min-width: 1680px) {
  .dLdNone {
    display: none !important;
  }

  .dLdInline {
    display: inline !important;
  }

  .dLdInlineBlock {
    display: inline-block !important;
  }

  .dLdBlock {
    display: block !important;
  }

  .dLdTable {
    display: table !important;
  }

  .dLdTableRow {
    display: table-row !important;
  }

  .dLdTableCell {
    display: table-cell !important;
  }

  .dLdFlex {
    display: flex !important;
  }

  .dLdInlineFlex {
    display: inline-flex !important;
  }
}

@media print {
  .dPrintNone {
    display: none !important;
  }

  .dPrintInline {
    display: inline !important;
  }

  .dPrintInlineBlock {
    display: inline-block !important;
  }

  .dPrintBlock {
    display: block !important;
  }

  .dPrintTable {
    display: table !important;
  }

  .dPrintTableRow {
    display: table-row !important;
  }

  .dPrintTableCell {
    display: table-cell !important;
  }

  .dPrintFlex {
    display: flex !important;
  }

  .dPrintInlineFlex {
    display: inline-flex !important;
  }
}

.flexRow {
  flex-direction: row !important;
}

.flexColumn {
  flex-direction: column !important;
}

.flexRowReverse {
  flex-direction: row-reverse !important;
}

.flexColumnReverse {
  flex-direction: column-reverse !important;
}

.flexWrap {
  flex-wrap: wrap !important;
}

.flexNowrap {
  flex-wrap: nowrap !important;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse !important;
}

.flexFill {
  flex: 1 1 auto !important;
}

.flexGrow0 {
  flex-grow: 0 !important;
}

.flexGrow1 {
  flex-grow: 1 !important;
}

.flexShrink0 {
  flex-shrink: 0 !important;
}

.flexShrink1 {
  flex-shrink: 1 !important;
}

.justifyContentStart {
  justify-content: flex-start !important;
}

.justifyContentEnd {
  justify-content: flex-end !important;
}

.justifyContentCenter {
  justify-content: center !important;
}

.justifyContentBetween {
  justify-content: space-between !important;
}

.justifyContentAround {
  justify-content: space-around !important;
}

.alignItemsStart {
  align-items: flex-start !important;
}

.alignItemsEnd {
  align-items: flex-end !important;
}

.alignItemsCenter {
  align-items: center !important;
}

.alignItemsBaseline {
  align-items: baseline !important;
}

.alignItemsStretch {
  align-items: stretch !important;
}

.alignContentStart {
  align-content: flex-start !important;
}

.alignContentEnd {
  align-content: flex-end !important;
}

.alignContentCenter {
  align-content: center !important;
}

.alignContentBetween {
  align-content: space-between !important;
}

.alignContentAround {
  align-content: space-around !important;
}

.alignContentStretch {
  align-content: stretch !important;
}

.alignSelfAuto {
  align-self: auto !important;
}

.alignSelfStart {
  align-self: flex-start !important;
}

.alignSelfEnd {
  align-self: flex-end !important;
}

.alignSelfCenter {
  align-self: center !important;
}

.alignSelfBaseline {
  align-self: baseline !important;
}

.alignSelfStretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flexSmRow {
    flex-direction: row !important;
  }

  .flexSmColumn {
    flex-direction: column !important;
  }

  .flexSmRowReverse {
    flex-direction: row-reverse !important;
  }

  .flexSmColumnReverse {
    flex-direction: column-reverse !important;
  }

  .flexSmWrap {
    flex-wrap: wrap !important;
  }

  .flexSmNowrap {
    flex-wrap: nowrap !important;
  }

  .flexSmWrapReverse {
    flex-wrap: wrap-reverse !important;
  }

  .flexSmFill {
    flex: 1 1 auto !important;
  }

  .flexSmGrow0 {
    flex-grow: 0 !important;
  }

  .flexSmGrow1 {
    flex-grow: 1 !important;
  }

  .flexSmShrink0 {
    flex-shrink: 0 !important;
  }

  .flexSmShrink1 {
    flex-shrink: 1 !important;
  }

  .justifyContentSmStart {
    justify-content: flex-start !important;
  }

  .justifyContentSmEnd {
    justify-content: flex-end !important;
  }

  .justifyContentSmCenter {
    justify-content: center !important;
  }

  .justifyContentSmBetween {
    justify-content: space-between !important;
  }

  .justifyContentSmAround {
    justify-content: space-around !important;
  }

  .alignItemsSmStart {
    align-items: flex-start !important;
  }

  .alignItemsSmEnd {
    align-items: flex-end !important;
  }

  .alignItemsSmCenter {
    align-items: center !important;
  }

  .alignItemsSmBaseline {
    align-items: baseline !important;
  }

  .alignItemsSmStretch {
    align-items: stretch !important;
  }

  .alignContentSmStart {
    align-content: flex-start !important;
  }

  .alignContentSmEnd {
    align-content: flex-end !important;
  }

  .alignContentSmCenter {
    align-content: center !important;
  }

  .alignContentSmBetween {
    align-content: space-between !important;
  }

  .alignContentSmAround {
    align-content: space-around !important;
  }

  .alignContentSmStretch {
    align-content: stretch !important;
  }

  .alignSelfSmAuto {
    align-self: auto !important;
  }

  .alignSelfSmStart {
    align-self: flex-start !important;
  }

  .alignSelfSmEnd {
    align-self: flex-end !important;
  }

  .alignSelfSmCenter {
    align-self: center !important;
  }

  .alignSelfSmBaseline {
    align-self: baseline !important;
  }

  .alignSelfSmStretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flexMdRow {
    flex-direction: row !important;
  }

  .flexMdColumn {
    flex-direction: column !important;
  }

  .flexMdRowReverse {
    flex-direction: row-reverse !important;
  }

  .flexMdColumnReverse {
    flex-direction: column-reverse !important;
  }

  .flexMdWrap {
    flex-wrap: wrap !important;
  }

  .flexMdNowrap {
    flex-wrap: nowrap !important;
  }

  .flexMdWrapReverse {
    flex-wrap: wrap-reverse !important;
  }

  .flexMdFill {
    flex: 1 1 auto !important;
  }

  .flexMdGrow0 {
    flex-grow: 0 !important;
  }

  .flexMdGrow1 {
    flex-grow: 1 !important;
  }

  .flexMdShrink0 {
    flex-shrink: 0 !important;
  }

  .flexMdShrink1 {
    flex-shrink: 1 !important;
  }

  .justifyContentMdStart {
    justify-content: flex-start !important;
  }

  .justifyContentMdEnd {
    justify-content: flex-end !important;
  }

  .justifyContentMdCenter {
    justify-content: center !important;
  }

  .justifyContentMdBetween {
    justify-content: space-between !important;
  }

  .justifyContentMdAround {
    justify-content: space-around !important;
  }

  .alignItemsMdStart {
    align-items: flex-start !important;
  }

  .alignItemsMdEnd {
    align-items: flex-end !important;
  }

  .alignItemsMdCenter {
    align-items: center !important;
  }

  .alignItemsMdBaseline {
    align-items: baseline !important;
  }

  .alignItemsMdStretch {
    align-items: stretch !important;
  }

  .alignContentMdStart {
    align-content: flex-start !important;
  }

  .alignContentMdEnd {
    align-content: flex-end !important;
  }

  .alignContentMdCenter {
    align-content: center !important;
  }

  .alignContentMdBetween {
    align-content: space-between !important;
  }

  .alignContentMdAround {
    align-content: space-around !important;
  }

  .alignContentMdStretch {
    align-content: stretch !important;
  }

  .alignSelfMdAuto {
    align-self: auto !important;
  }

  .alignSelfMdStart {
    align-self: flex-start !important;
  }

  .alignSelfMdEnd {
    align-self: flex-end !important;
  }

  .alignSelfMdCenter {
    align-self: center !important;
  }

  .alignSelfMdBaseline {
    align-self: baseline !important;
  }

  .alignSelfMdStretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {
  .flexLgRow {
    flex-direction: row !important;
  }

  .flexLgColumn {
    flex-direction: column !important;
  }

  .flexLgRowReverse {
    flex-direction: row-reverse !important;
  }

  .flexLgColumnReverse {
    flex-direction: column-reverse !important;
  }

  .flexLgWrap {
    flex-wrap: wrap !important;
  }

  .flexLgNowrap {
    flex-wrap: nowrap !important;
  }

  .flexLgWrapReverse {
    flex-wrap: wrap-reverse !important;
  }

  .flexLgFill {
    flex: 1 1 auto !important;
  }

  .flexLgGrow0 {
    flex-grow: 0 !important;
  }

  .flexLgGrow1 {
    flex-grow: 1 !important;
  }

  .flexLgShrink0 {
    flex-shrink: 0 !important;
  }

  .flexLgShrink1 {
    flex-shrink: 1 !important;
  }

  .justifyContentLgStart {
    justify-content: flex-start !important;
  }

  .justifyContentLgEnd {
    justify-content: flex-end !important;
  }

  .justifyContentLgCenter {
    justify-content: center !important;
  }

  .justifyContentLgBetween {
    justify-content: space-between !important;
  }

  .justifyContentLgAround {
    justify-content: space-around !important;
  }

  .alignItemsLgStart {
    align-items: flex-start !important;
  }

  .alignItemsLgEnd {
    align-items: flex-end !important;
  }

  .alignItemsLgCenter {
    align-items: center !important;
  }

  .alignItemsLgBaseline {
    align-items: baseline !important;
  }

  .alignItemsLgStretch {
    align-items: stretch !important;
  }

  .alignContentLgStart {
    align-content: flex-start !important;
  }

  .alignContentLgEnd {
    align-content: flex-end !important;
  }

  .alignContentLgCenter {
    align-content: center !important;
  }

  .alignContentLgBetween {
    align-content: space-between !important;
  }

  .alignContentLgAround {
    align-content: space-around !important;
  }

  .alignContentLgStretch {
    align-content: stretch !important;
  }

  .alignSelfLgAuto {
    align-self: auto !important;
  }

  .alignSelfLgStart {
    align-self: flex-start !important;
  }

  .alignSelfLgEnd {
    align-self: flex-end !important;
  }

  .alignSelfLgCenter {
    align-self: center !important;
  }

  .alignSelfLgBaseline {
    align-self: baseline !important;
  }

  .alignSelfLgStretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flexXlRow {
    flex-direction: row !important;
  }

  .flexXlColumn {
    flex-direction: column !important;
  }

  .flexXlRowReverse {
    flex-direction: row-reverse !important;
  }

  .flexXlColumnReverse {
    flex-direction: column-reverse !important;
  }

  .flexXlWrap {
    flex-wrap: wrap !important;
  }

  .flexXlNowrap {
    flex-wrap: nowrap !important;
  }

  .flexXlWrapReverse {
    flex-wrap: wrap-reverse !important;
  }

  .flexXlFill {
    flex: 1 1 auto !important;
  }

  .flexXlGrow0 {
    flex-grow: 0 !important;
  }

  .flexXlGrow1 {
    flex-grow: 1 !important;
  }

  .flexXlShrink0 {
    flex-shrink: 0 !important;
  }

  .flexXlShrink1 {
    flex-shrink: 1 !important;
  }

  .justifyContentXlStart {
    justify-content: flex-start !important;
  }

  .justifyContentXlEnd {
    justify-content: flex-end !important;
  }

  .justifyContentXlCenter {
    justify-content: center !important;
  }

  .justifyContentXlBetween {
    justify-content: space-between !important;
  }

  .justifyContentXlAround {
    justify-content: space-around !important;
  }

  .alignItemsXlStart {
    align-items: flex-start !important;
  }

  .alignItemsXlEnd {
    align-items: flex-end !important;
  }

  .alignItemsXlCenter {
    align-items: center !important;
  }

  .alignItemsXlBaseline {
    align-items: baseline !important;
  }

  .alignItemsXlStretch {
    align-items: stretch !important;
  }

  .alignContentXlStart {
    align-content: flex-start !important;
  }

  .alignContentXlEnd {
    align-content: flex-end !important;
  }

  .alignContentXlCenter {
    align-content: center !important;
  }

  .alignContentXlBetween {
    align-content: space-between !important;
  }

  .alignContentXlAround {
    align-content: space-around !important;
  }

  .alignContentXlStretch {
    align-content: stretch !important;
  }

  .alignSelfXlAuto {
    align-self: auto !important;
  }

  .alignSelfXlStart {
    align-self: flex-start !important;
  }

  .alignSelfXlEnd {
    align-self: flex-end !important;
  }

  .alignSelfXlCenter {
    align-self: center !important;
  }

  .alignSelfXlBaseline {
    align-self: baseline !important;
  }

  .alignSelfXlStretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flexXxlRow {
    flex-direction: row !important;
  }

  .flexXxlColumn {
    flex-direction: column !important;
  }

  .flexXxlRowReverse {
    flex-direction: row-reverse !important;
  }

  .flexXxlColumnReverse {
    flex-direction: column-reverse !important;
  }

  .flexXxlWrap {
    flex-wrap: wrap !important;
  }

  .flexXxlNowrap {
    flex-wrap: nowrap !important;
  }

  .flexXxlWrapReverse {
    flex-wrap: wrap-reverse !important;
  }

  .flexXxlFill {
    flex: 1 1 auto !important;
  }

  .flexXxlGrow0 {
    flex-grow: 0 !important;
  }

  .flexXxlGrow1 {
    flex-grow: 1 !important;
  }

  .flexXxlShrink0 {
    flex-shrink: 0 !important;
  }

  .flexXxlShrink1 {
    flex-shrink: 1 !important;
  }

  .justifyContentXxlStart {
    justify-content: flex-start !important;
  }

  .justifyContentXxlEnd {
    justify-content: flex-end !important;
  }

  .justifyContentXxlCenter {
    justify-content: center !important;
  }

  .justifyContentXxlBetween {
    justify-content: space-between !important;
  }

  .justifyContentXxlAround {
    justify-content: space-around !important;
  }

  .alignItemsXxlStart {
    align-items: flex-start !important;
  }

  .alignItemsXxlEnd {
    align-items: flex-end !important;
  }

  .alignItemsXxlCenter {
    align-items: center !important;
  }

  .alignItemsXxlBaseline {
    align-items: baseline !important;
  }

  .alignItemsXxlStretch {
    align-items: stretch !important;
  }

  .alignContentXxlStart {
    align-content: flex-start !important;
  }

  .alignContentXxlEnd {
    align-content: flex-end !important;
  }

  .alignContentXxlCenter {
    align-content: center !important;
  }

  .alignContentXxlBetween {
    align-content: space-between !important;
  }

  .alignContentXxlAround {
    align-content: space-around !important;
  }

  .alignContentXxlStretch {
    align-content: stretch !important;
  }

  .alignSelfXxlAuto {
    align-self: auto !important;
  }

  .alignSelfXxlStart {
    align-self: flex-start !important;
  }

  .alignSelfXxlEnd {
    align-self: flex-end !important;
  }

  .alignSelfXxlCenter {
    align-self: center !important;
  }

  .alignSelfXxlBaseline {
    align-self: baseline !important;
  }

  .alignSelfXxlStretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1680px) {
  .flexLdRow {
    flex-direction: row !important;
  }

  .flexLdColumn {
    flex-direction: column !important;
  }

  .flexLdRowReverse {
    flex-direction: row-reverse !important;
  }

  .flexLdColumnReverse {
    flex-direction: column-reverse !important;
  }

  .flexLdWrap {
    flex-wrap: wrap !important;
  }

  .flexLdNowrap {
    flex-wrap: nowrap !important;
  }

  .flexLdWrapReverse {
    flex-wrap: wrap-reverse !important;
  }

  .flexLdFill {
    flex: 1 1 auto !important;
  }

  .flexLdGrow0 {
    flex-grow: 0 !important;
  }

  .flexLdGrow1 {
    flex-grow: 1 !important;
  }

  .flexLdShrink0 {
    flex-shrink: 0 !important;
  }

  .flexLdShrink1 {
    flex-shrink: 1 !important;
  }

  .justifyContentLdStart {
    justify-content: flex-start !important;
  }

  .justifyContentLdEnd {
    justify-content: flex-end !important;
  }

  .justifyContentLdCenter {
    justify-content: center !important;
  }

  .justifyContentLdBetween {
    justify-content: space-between !important;
  }

  .justifyContentLdAround {
    justify-content: space-around !important;
  }

  .alignItemsLdStart {
    align-items: flex-start !important;
  }

  .alignItemsLdEnd {
    align-items: flex-end !important;
  }

  .alignItemsLdCenter {
    align-items: center !important;
  }

  .alignItemsLdBaseline {
    align-items: baseline !important;
  }

  .alignItemsLdStretch {
    align-items: stretch !important;
  }

  .alignContentLdStart {
    align-content: flex-start !important;
  }

  .alignContentLdEnd {
    align-content: flex-end !important;
  }

  .alignContentLdCenter {
    align-content: center !important;
  }

  .alignContentLdBetween {
    align-content: space-between !important;
  }

  .alignContentLdAround {
    align-content: space-around !important;
  }

  .alignContentLdStretch {
    align-content: stretch !important;
  }

  .alignSelfLdAuto {
    align-self: auto !important;
  }

  .alignSelfLdStart {
    align-self: flex-start !important;
  }

  .alignSelfLdEnd {
    align-self: flex-end !important;
  }

  .alignSelfLdCenter {
    align-self: center !important;
  }

  .alignSelfLdBaseline {
    align-self: baseline !important;
  }

  .alignSelfLdStretch {
    align-self: stretch !important;
  }
}

.marginXAuto {
  margin-left: auto;
  margin-right: auto;
}

.marginYAuto {
  margin-top: auto;
  margin-bottom: auto;
}

.marginLeftAuto {
  margin-left: auto;
}

.marginRightAuto {
  margin-right: auto;
}

.marginTopAuto {
  margin-top: auto;
}

.marginBottomAuto {
  margin-bottom: auto;
}

.marginNone {
  margin: 0;
}

.paddingXNone {
  padding-right: 0;
  padding-left: 0;
}

.marginXNone {
  margin-right: 0;
  margin-left: 0;
}

.paddingYNone {
  padding-top: 0;
  padding-bottom: 0;
}

.marginYNone {
  margin-top: 0;
  margin-bottom: 0;
}

.paddingTopNone {
  padding-top: 0;
}

.marginTopNone {
  margin-top: 0;
}

.paddingRightNone {
  padding-right: 0;
}

.marginRightNone {
  margin-right: 0;
}

.paddingBottomNone {
  padding-bottom: 0;
}

.marginBottomNone {
  margin-bottom: 0;
}

.paddingLeftNone {
  padding-left: 0;
}

.marginLeftNone {
  margin-left: 0;
}

.innerNone {
  padding: 0;
}

.margin4 {
  margin: 4px;
}

.paddingX4 {
  padding-right: 4px;
  padding-left: 4px;
}

.marginX4 {
  margin-right: 4px;
  margin-left: 4px;
}

.paddingY4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.marginY4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.paddingTop4 {
  padding-top: 4px;
}

.marginTop4 {
  margin-top: 4px;
}

.paddingRight4 {
  padding-right: 4px;
}

.marginRight4 {
  margin-right: 4px;
}

.paddingBottom4 {
  padding-bottom: 4px;
}

.marginBottom4 {
  margin-bottom: 4px;
}

.paddingLeft4 {
  padding-left: 4px;
}

.marginLeft4 {
  margin-left: 4px;
}

.inner4 {
  padding: 4px;
}

.margin8 {
  margin: 8px;
}

.paddingX8 {
  padding-right: 8px;
  padding-left: 8px;
}

.marginX8 {
  margin-right: 8px;
  margin-left: 8px;
}

.paddingY8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.marginY8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.paddingTop8 {
  padding-top: 8px;
}

.marginTop8 {
  margin-top: 8px;
}

.paddingRight8 {
  padding-right: 8px;
}

.marginRight8 {
  margin-right: 8px;
}

.paddingBottom8 {
  padding-bottom: 8px;
}

.marginBottom8 {
  margin-bottom: 8px;
}

.paddingLeft8 {
  padding-left: 8px;
}

.marginLeft8 {
  margin-left: 8px;
}

.inner8 {
  padding: 8px;
}

.margin12 {
  margin: 12px;
}

.paddingX12 {
  padding-right: 12px;
  padding-left: 12px;
}

.marginX12 {
  margin-right: 12px;
  margin-left: 12px;
}

.paddingY12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.marginY12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.paddingTop12 {
  padding-top: 12px;
}

.marginTop12 {
  margin-top: 12px;
}

.paddingRight12 {
  padding-right: 12px;
}

.marginRight12 {
  margin-right: 12px;
}

.paddingBottom12 {
  padding-bottom: 12px;
}

.marginBottom12 {
  margin-bottom: 12px;
}

.paddingLeft12 {
  padding-left: 12px;
}

.marginLeft12 {
  margin-left: 12px;
}

.inner12 {
  padding: 12px;
}

.margin16 {
  margin: 16px;
}

.paddingX16 {
  padding-right: 16px;
  padding-left: 16px;
}

.marginX16 {
  margin-right: 16px;
  margin-left: 16px;
}

.paddingY16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.marginY16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.paddingTop16 {
  padding-top: 16px;
}

.marginTop16 {
  margin-top: 16px;
}

.paddingRight16 {
  padding-right: 16px;
}

.marginRight16 {
  margin-right: 16px;
}

.paddingBottom16 {
  padding-bottom: 16px;
}

.marginBottom16 {
  margin-bottom: 16px;
}

.paddingLeft16 {
  padding-left: 16px;
}

.marginLeft16 {
  margin-left: 16px;
}

.inner16 {
  padding: 16px;
}

.margin24 {
  margin: 24px;
}

.paddingX24 {
  padding-right: 24px;
  padding-left: 24px;
}

.marginX24 {
  margin-right: 24px;
  margin-left: 24px;
}

.paddingY24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.marginY24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.paddingTop24 {
  padding-top: 24px;
}

.marginTop24 {
  margin-top: 24px;
}

.paddingRight24 {
  padding-right: 24px;
}

.marginRight24 {
  margin-right: 24px;
}

.paddingBottom24 {
  padding-bottom: 24px;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.paddingLeft24 {
  padding-left: 24px;
}

.marginLeft24 {
  margin-left: 24px;
}

.inner24 {
  padding: 24px;
}

.margin32 {
  margin: 32px;
}

.paddingX32 {
  padding-right: 32px;
  padding-left: 32px;
}

.marginX32 {
  margin-right: 32px;
  margin-left: 32px;
}

.paddingY32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.marginY32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.paddingTop32 {
  padding-top: 32px;
}

.marginTop32 {
  margin-top: 32px;
}

.paddingRight32 {
  padding-right: 32px;
}

.marginRight32 {
  margin-right: 32px;
}

.paddingBottom32 {
  padding-bottom: 32px;
}

.marginBottom32 {
  margin-bottom: 32px;
}

.paddingLeft32 {
  padding-left: 32px;
}

.marginLeft32 {
  margin-left: 32px;
}

.inner32 {
  padding: 32px;
}

.tdTableWrapper {
  .tdTableCell {
    position: relative;
    border-left: 1px solid $blue-disabled;

    &:last-child {
      border-right: 1px solid $blue-disabled;
    }

    .tdTableCell {
      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.borderLeft {
  border-left: 1px solid $blue-disabled;
}

.borderRight {
  border-right: 1px solid $blue-disabled;
}

.borderBottom {
  border-bottom: 1px solid $blue-disabled;
}

.subtitle {
  font-size: 12px;
  line-height: 16px;
  color: $dark-grey;
}

.smallGutters {
  margin-left: -8px;
  margin-right: -8px;

  [class*="col"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.height100 {
  height: 100%;
}
