@import '../../styles/theme';

.button {
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  pointer-events: all;

  & svg {
    pointer-events: none;
    margin: 5px 0 0 0;
  }

  & svg path {
    pointer-events: none;
    fill: #6177ff;
  }

  &.disabled {
    cursor: default;

    & span,
    button,
    div {
      cursor: default;
      text-decoration: none;
      color: $dark-grey;
    }
  }
}

.medium {
  font-size: 12px;
  line-height: 16px;

  &.link {
    text-decoration: underline;
    cursor: pointer;
  }

  &.bold {
    font-weight: 500;
  }

  &.default {
    font-weight: 400;
  }
}

.large {
  font-size: 16px;
  line-height: 24px;

  &.link {
    text-decoration: underline;
  }

  &.bold {
    font-weight: 500;
  }

  &.default {
    font-weight: 400;
  }
}

.container {
  display: flex;
  width: 100%;
  height: 65px;

  &.active {
    justify-content: center;
    align-items: center;
  }

  &.inactive {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    & > button {
      margin: 0 0 6px 25px;

      & svg {
        margin: 0 0 0 25px;
      }
    }
  }
}

.loader {
  margin-left: 25px;
}

.externalError {
  margin-left: 25px;
}
