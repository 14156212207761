@import '../../styles/KrTheme.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  overflow: hidden;
}

.documentLink {
  display: block;
  width: 100%;
  text-decoration: none;

  &.unclickable {
    cursor: default;
    pointer-events: none;
  }
}

.icon {
  width: 42px;
  height: 42px;
  background-repeat: no-repeat;
  background-position: center;
  float: left;

  &.default {
    background-image: url('./icons/file-default.svg');
  }

  &.doc {
    background-image: url('./icons/file-doc.svg');
  }

  &.docx {
    background-image: url('./icons/file-docx.svg');
  }

  &.xls {
    background-image: url('icons/file-xls.svg');
  }

  &.xlsx {
    background-image: url('icons/file-xlsx.svg');
  }

  &.pdf {
    background-image: url('./icons/file-pdf.svg');
  }

  &.rar {
    background-image: url('./icons/file-rar.svg');
    background-size: 48px;
  }

  &.zip {
    background-image: url('./icons/file-zip.svg');
    background-size: 48px;
  }

  &.xml {
    background-image: url('icons/file-xml.svg');
  }

  &.csv {
    background-image: url('icons/file-csv.svg');
  }

  &.txt {
    background-image: url('icons/file-txt.svg');
  }

  &.jpeg,
  &.jpg,
  &.tiff,
  &.tif,
  &.png,
  &.gif,
  &.bmp {
    background-image: url('icons/file-image.svg');
  }
}

.documentInfo {
  padding: 0 4px 0 4px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  & > p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.documentName {
  font-size: 16px;
  line-height: 24px;
  color: $blue-normal;
}

.documentName,
.documentInfoData {
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    overflow: hidden;
  }
}

.subtitle {
  font-size: 12px;
  line-height: 16px;
  color: $dark-grey;
}

.meta {
  margin-right: 8px;
}

.canDelete {
  padding-right: 32px;
}

.deleteButton {
  display: block;
  position: absolute;
  top: 6px;
  right: 0;
  line-height: 12px;
  color: $blue-normal;
  cursor: pointer;
}
