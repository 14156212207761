@import '../../styles/KrTheme.scss';

.document {
  display: flex;

  &__icon {
    width: 42px;
    height: 42px;

    &--docx {
      background-image: url('./icons/file-docx.svg');
    }

    &--exc {
      background-image: url('./icons/file-exc.svg');
    }

    &--pdf {
      background-image: url('./icons/file-pdf.svg');
    }

    &--rar {
      background-image: url('./icons/file-rar.svg');
    }

    &--zip {
      background-image: url('./icons/file-zip.svg');
    }
  }

  &__info {
    padding: 0 4px 0 4px;
    width: calc(100% - 42px);
  }

  &__info-name {
    font-size: 16px;
    line-height: 24px;
    color: $blue-normal;
  }

  &__info-name,
  &__info-data {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;

    &:hover {
      overflow: hidden;
    }
  }
}

.subtitle {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: $dark-grey;
}
