@import '../../../../styles/KrTheme.scss';

.ContractStatus {
  width: 196px;
  font-size: 16px;
  line-height: 24px;

  & svg {
    margin: 0 8px 0 4px;
  }

  & .mutuallySigned {
    display: flex;
    align-items: center;
    color: $green_01;
  }

  & .oneSideSigned {
    & .currentStatus {
      display: flex;
      align-items: center;
      color: $blue-normal;
    }

    & .comment {
      color: $dark-grey;
    }
  }
}
