@import "../../../../styles/theme";

.attachmentsWrapper {
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 40px;
  }
}

.attachWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}

.attach {
  width: 60%;
}

.signButton {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.errorMessage {
  color: $error-text;
}
