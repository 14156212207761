@import "../../styles/theme";

.openPanelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $form-max-width;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $light;
}

.panelHeader {
  padding: 0 56px 0 72px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 104px;
  height: auto;
  align-items: center;
  background-color: $disabled;
  box-sizing: border-box;

  @media (max-width: $breakpoint-medium) {
    padding: 15px 16px 17px;
    min-height: 56px;
    flex-direction: column;
  }
}

.title {
  @media (max-width: $breakpoint-medium) {
    align-self: flex-start;
    flex-direction: column;
  }

  width: auto;
}

.panelBody {
  width: 100%;
  background-color: $white;
}

.roleWrapper {
  color: $white;
  background-color: $light-grey;
}

.blueLight {
  background-color: $blue-light;
}

.lightGray {
  background-color: $light-grey;
}

.darkGray {
  background-color: $dark-grey;
}

.white {
  background-color: $white;
}

.disabled {
  background-color: $disabled;
}

.darkBlue {
  background-color: $dark-blue;
}
